import { colors } from '../../colors';
var gallery = {
  bgColor: colors.softPink,
  secondaryBgColor: colors.white,
  textBg: colors.white,
  textColor: colors.pinkishRed,
  modalBgColor: colors.paleSkyBlue,
  closeIconColor: colors.black,
  modalHeadingColor: colors.black,
  modalCaptionColor: colors.pinkishRed,
  modalParaColor: colors.black,
  textColorDark: colors.black,
  textColorLight: colors.white
};
export default gallery;