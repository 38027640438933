import { colors } from '../../colors';
var staff = {
  bgPrimaryColor: colors.ivory,
  bgSecondaryColor: colors.sandy,
  bgColorBio: colors.ivory,
  breadcrumbColor: colors.clearBlue,
  fillColor: colors.orangish,
  fillHoverColor: colors.white,
  iconColor: colors.white,
  iconHoverColor: colors.orangish,
  iconBorderColor: colors.orangish,
  iconBorderHoverColor: colors.orangish,
  bladeSubHeadingColor: colors.clearBlue,
  fiveBladeLinkColor: colors.orangish,
  textColorDark: colors.charcoalGrey,
  textColorLight: colors.white
};
export default staff;