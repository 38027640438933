import { colors } from '../../colors';
var reviewCard = {
  borderRadius: 0,
  reviewLinkColor: colors.neonPurple,
  iconColor: colors.neonPurple,
  reviewLinkHoverColor: colors.amethyst,
  reviewTextColor: colors.slateGrey,
  cardBgColor: colors.white,
  bgColor: colors.iceBlue,
  headlineColor: colors.slateGrey,
  boxShadow: 'none',
  cardRadius: '20px',
  textColorDark: colors.slateGrey,
  textColorLight: colors.white
};
export default reviewCard;