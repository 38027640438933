import { colors } from '../../colors';
var footer = {
  textColor: colors.nevada,
  primaryColor: colors.graphite,
  bgColor: colors.whiteish,
  textHoverColor: colors.graphite,
  textAddressColor: colors.graphite,
  textOperationHoursColor: colors.graphite,
  textPrimaryLinksColor: colors.duskBlue,
  textPrimaryLinksHoverColor: colors.rust,
  seperatorColor: colors.overcast,
  tertiaryLinkLetterSpacing: '0.13px',
  textColorDark: colors.graphite,
  textColorLight: colors.white
};
export default footer;