import { colors } from '../../colors';
var service = {
  bgColor: colors.alabaster,
  borderColor: colors.overcast,
  bgColorBlade: colors.alabaster,
  carouselDotColor: colors.slateGreen,
  linkColor: colors.pine,
  textColorDark: colors.ash,
  textColorLight: colors.white
};
export default service;