import { colors } from '../../colors';
var staff = {
  bgPrimaryColor: colors.paleGrey,
  bgSecondaryColor: colors.white,
  bgColorBio: colors.white,
  breadcrumbColor: colors.red,
  fillColor: colors.white,
  fillHoverColor: colors.white,
  iconColor: colors.black,
  iconHoverColor: colors.red,
  iconBorderColor: colors.black,
  iconBorderHoverColor: colors.red,
  bladeSubHeadingColor: colors.brightRed,
  fiveBladeLinkColor: colors.brightRed,
  textColorDark: colors.ash,
  textColorLight: colors.white
};
export default staff;