import { colors } from '../../colors';
var staff = {
  removeAnchorTextArrow: true /* remove this after getting already existing theme.displayNoRightArrow to work */,
  bgPrimaryColor: colors.equineSnow,
  bgSecondaryColor: colors.equineSnow,
  bgColorBio: colors.equineSnow,
  breadcrumbColor: colors.equineSnow,
  bladeSubHeadingColor: colors.equineIron,
  headingColor: colors.equineIron,
  fillColor: colors.equineSnow,
  fillHoverColor: colors.equineStone,
  iconColor: colors.equineStone,
  iconHoverColor: colors.equineSnow,
  iconBorderColor: colors.equinePearl,
  iconBorderHoverColor: colors.equinePearl,
  fiveBladeLinkColor: colors.equineStone,
  fiveBladeBgColor: colors.equinePearl /* to allow for the five-blade to a have a different bg collor than the full bio  */,
  textColorDark: colors.equineIron,
  textColorLight: colors.equineSnow,
  textToUpper: true /* I don't think there should be a shadow here */,
  fiveBladeMarginTop: '20px',
  staffHeadingSidePadding: '20px',
  removeContainerTopPadding: true,
  mobileStaffImageWithSidePadding: true,
  StaffBioDetailPadding: '40px 20px'
};
export default staff;