import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function Youtube(props) {
  return __jsx("svg", _extends({
    viewBox: "0 0 18.1 12.7"
  }, props), __jsx("path", {
    d: "M17.7 2c-.2-.8-.8-1.4-1.6-1.6C13.7.1 11.4 0 9 0 6.7 0 4.3.1 2 .4 1.2.6.6 1.2.4 2 .1 3.4 0 4.9 0 6.4s.1 2.9.4 4.4c.2.8.8 1.4 1.6 1.6 2.3.3 4.7.4 7.1.4s4.7-.1 7.1-.4c.8-.2 1.4-.8 1.6-1.6.3-1.4.4-2.9.4-4.4-.1-1.5-.2-3-.5-4.4zM7.2 9.1V3.7l4.7 2.7-4.7 2.7z"
  }));
}
export default Youtube;