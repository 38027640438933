import { colors } from '../../colors';
var threeTile = {
  borderColor: colors.overcast,
  headingColor: colors.black,
  paraColor: colors.black,
  subHeadingColor: colors.pinkishRed,
  bgColor: colors.white,
  ctaColor: colors.pinkishRed,
  cardRadius: '20px',
  btnBgColor: colors.pinkishRed,
  btnHoverColor: colors.rouge,
  textColorDark: colors.black,
  textColorLight: colors.white,
  cardBgColorVariationC: colors.white
};
export default threeTile;