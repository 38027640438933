import { css } from 'styled-components';
var textures = [{
  key: 'leavesOrganicLightTan',
  label: 'Leaves Organic Light Tan',
  style: css(["background-color:rgb(246,242,239);background-image:url('/images/textures/EarthyV1/leavesOrganicLightTan.svg');background-repeat:repeat;background-size:476px 476px;"])
}, {
  key: 'leavesOrganicGreen',
  label: 'Leaves Organic Green',
  style: css(["background-color:rgb(110,127,101);background-image:url('/images/textures/EarthyV1/leavesOrganicGreen.svg');background-repeat:repeat;background-size:476px 476px;"])
}, {
  key: 'waveLightTan',
  label: 'Wave Light Tan',
  style: css(["background-color:rgb(246,242,239);background-image:url('/images/textures/EarthyV1/waveLightTan.svg');background-repeat:repeat;background-size:500px 500px;"])
}, {
  key: 'waveGreen',
  label: 'Wave Green',
  style: css(["background-color:rgb(110,127,101);background-image:url('/images/textures/EarthyV1/waveGreen.svg');background-repeat:repeat;background-size:500px 500px;"])
}, {
  key: 'woodgrainLightTan',
  label: 'Woodgrain Light Tan',
  style: css(["background-color:rgb(246,242,239);background-image:url('/images/textures/EarthyV1/woodgrainLightTan.svg');background-repeat:repeat;background-size:500px 501px;"])
}, {
  key: 'woodgrainGreen',
  label: 'Woodgrain Green',
  style: css(["background-color:rgb(110,127,101);background-image:url('/images/textures/EarthyV1/woodgrainGreen.svg');background-repeat:repeat;background-size:500px 501px;"])
}, {
  key: 'leavesGeometricLightTan',
  label: 'Leaves Geometric Light Tan',
  style: css(["background-color:rgb(246,242,239);background-image:url('/images/textures/EarthyV1/leavesGeometricLightTan.svg');background-repeat:repeat;background-size:400px 402px;"])
}, {
  key: 'leavesGeometricGreen',
  label: 'leaves Geometric Green',
  style: css(["background-color:rgb(110,127,101);background-image:url('/images/textures/EarthyV1/leavesGeometricGreen.svg');background-repeat:repeat;background-size:400px 402px;"])
}];
export default textures;