import { colors } from '../../colors';
var staff = {
  bgPrimaryColor: colors.whiteish,
  bgSecondaryColor: colors.whiteish,
  bgColorBio: colors.white,
  breadcrumbColor: colors.graphite,
  fillColor: colors.white,
  fillHoverColor: colors.white,
  iconColor: colors.duskBlue,
  iconHoverColor: colors.deepWater,
  iconBorderColor: colors.duskBlue,
  iconBorderHoverColor: colors.deepWater,
  bladeSubHeadingColor: colors.graphite,
  fiveBladeLinkColor: colors.duskBlue,
  textColorDark: colors.graphite,
  textColorLight: colors.white
};
export default staff;