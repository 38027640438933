import { colors } from '../../colors';
var reviewCard = {
  borderRadius: 0,
  reviewTextUppercase: true,
  reviewLinkUppercase: true,
  reviewerNameUppercase: false,
  reviewsHeadlineUppercase: true,
  reviewLinkColor: colors.nvaTeal,
  iconColor: colors.nvaTeal,
  reviewLinkHoverColor: colors.nvaDarkTeal,
  reviewTextColor: colors.nvaBlack,
  cardBgColor: colors.white,
  bgColor: colors.offWhite,
  headlineColor: colors.nvaBlack,
  boxShadow: 'none',
  textColorDark: colors.ash,
  textColorLight: colors.white
};
export default reviewCard;