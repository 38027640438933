import { colors } from '../../colors';
var button = {
  //Hero button
  linkColorHero: colors.white,
  linkColorHeroHover: colors.white,
  backgroundColorHero: colors.slateGreen,
  backgroundColorHoverHero: colors.militaryGreen,
  borderRadiusHero: '0',
  linkLineHeightHero: '30px',
  heroButtonPadding: '20px 35px',
  //primary button
  linkColorPrimary: colors.white,
  linkColorPrimaryHover: colors.white,
  backgroundColorPrimary: colors.slateGreen,
  backgroundColorHoverPrimary: colors.militaryGreen,
  borderRadiusPrimary: '0',
  //secondary button
  linkColorSecondary: colors.slateGreen,
  backgroundColorSecondary: colors.white,
  backgroundColorHoverSecondary: colors.slateGreen,
  borderRadiusSecondary: '0',
  linkColorSecondaryHover: colors.white,
  borderColorSecondary: colors.slateGreen,
  borderColorSecondaryHover: 'transparent'
};
export default button;