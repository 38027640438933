import { colors } from '../../colors';
var footer = {
  textColor: colors.white,
  primaryColor: colors.white,
  bgColor: colors.marine,
  textHoverColor: colors.white,
  textAddressColor: colors.white,
  textOperationHoursColor: colors.white,
  textPrimaryLinksColor: colors.white,
  textPrimaryLinksHoverColor: colors.dirtBrown,
  seperatorColor: colors.overcast,
  primaryLinkLineHeight: '20px',
  textColorLight: colors.white,
  textColorDark: colors.metal
};
export default footer;