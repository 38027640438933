import { colors } from '../../colors';
var header = {
  primaryColor: colors.darkSage,
  hoverColor: colors.brickOrange,
  borderColor: colors.overcast,
  bgColor: colors.cloudy,
  barColor: colors.darkSage,
  navContactLinkHoverTextDecoration: "underline",
  navUtilityLinkColor: colors.darkSage,
  navUtilityLinkHoverColor: colors.charcoal,
  navUtilityLinkTextDecoration: "none",
  navUtilityLinkHoverTextDecoration: "underline",
  topNavLineHeight: '16px'
};
export default header;