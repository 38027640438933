import { colors } from '../../colors';
var button = {
  //Hero button
  linkColorHero: colors.white,
  linkColorHeroHover: colors.white,
  backgroundColorHero: colors.azure,
  backgroundColorHoverHero: colors.deepSeaBlue,
  borderRadiusHero: '0',
  linkLineHeightHero: '30px',
  heroButtonPadding: '20px 35px',
  //primary button
  linkColorPrimary: colors.azure,
  linkColorPrimaryHover: colors.deepSeaBlue,
  backgroundColorPrimary: colors.white,
  backgroundColorHoverPrimary: colors.white,
  borderColorPrimary: colors.azure,
  borderColorPrimaryHover: colors.deepSeaBlue,
  borderRadiusPrimary: '0',
  //secondary button
  linkColorSecondary: colors.azure,
  backgroundColorSecondary: colors.white,
  backgroundColorHoverSecondary: colors.white,
  borderRadiusSecondary: '0',
  linkColorSecondaryHover: colors.deepSeaBlue,
  borderColorSecondary: colors.azure,
  borderColorSecondaryHover: colors.deepSeaBlue
};
export default button;