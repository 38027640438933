import { colors } from '../../colors';
var header = {
  primaryColor: colors.metal,
  hoverColor: colors.dirtBrown,
  borderColor: colors.overcast,
  bgColor: colors.powder,
  barColor: colors.marine,
  navContactLinkHoverTextDecoration: "underline",
  navUtilityLinkColor: colors.metal,
  navUtilityLinkHoverColor: colors.metal,
  navUtilityLinkTextDecoration: "none",
  navUtilityLinkHoverTextDecoration: "underline"
};
export default header;