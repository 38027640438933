/**
 * @file Manages the fonts values for the theme Modern V1.
 * @author kkumari
 */export default {
  FONT_COURGETTE_REGULAR: 'CourgetteRegular, serif',
  FONT_OPENSANS_SEMI_BOLD: 'OpenSansSemiBold, sans-serif',
  FONT_OPENSANS_REGULAR: 'OpenSansRegular, sans-serif',
  FONT_SIZE_BASE: '16px',
  FONT_SIZE_SMALL: '10px',
  FONT_SIZE_DEFAULT: '14px',
  FONT_LETTER_SPACING_DEFAULT: '1.4px',
  FONT_LINE_HEIGHT_DEFAULT: '18px',
  FONT_ICONS: 'Arial, sans-serif',
  FONT_WEIGHT_BOLD: 'bold',
  FONT_WEIGHT_NORMAL: 'normal',
  FONT_WEIGHT_600: '600',
  FONT_WEIGHT_500: '500',
  FONT_WEIGHT_300: '300',
  //Mobile values
  //H1
  FONT_SIZE_H1: '36px',
  LINE_HEIGHT_H1: '40px',
  LETTER_SPACING_H1: 'normal',
  //H2
  FONT_SIZE_H2: '30px',
  LINE_HEIGHT_H2: '38px',
  LETTER_SPACING_H2: 'normal',
  //H3
  FONT_SIZE_H3: '24px',
  LINE_HEIGHT_H3: '34px',
  LETTER_SPACING_H3: 'normal',
  //H4
  FONT_SIZE_H4: '13px',
  LINE_HEIGHT_H4: '18px',
  LETTER_SPACING_H4: 'normal',
  //H5
  FONT_SIZE_H5: '12px',
  LINE_HEIGHT_H5: '17px',
  LETTER_SPACING_H5: '0.12px',
  //P
  FONT_SIZE_P: '14px',
  LINE_HEIGHT_P: '25px',
  LETTER_SPACING_P: 'normal',
  DESKTOP: {
    //Desktop values
    //H1
    FONT_SIZE_H1: '54px',
    LINE_HEIGHT_H1: '64px',
    LETTER_SPACING_H1: 'normal',
    //H2
    FONT_SIZE_H2: '32px',
    LINE_HEIGHT_H2: '46px',
    LETTER_SPACING_H2: 'normal',
    //H3
    FONT_SIZE_H3: '28px',
    LINE_HEIGHT_H3: '38px',
    LETTER_SPACING_H3: 'normal',
    //H4
    FONT_SIZE_H4: '15px',
    LINE_HEIGHT_H4: '20px',
    LETTER_SPACING_H4: '1.2px',
    //H5
    FONT_SIZE_H5: '12px',
    LINE_HEIGHT_H5: '17px',
    LETTER_SPACING_H5: '0.12px',
    //P
    FONT_SIZE_P: '15px',
    LINE_HEIGHT_P: '28px',
    LETTER_SPACING_P: 'normal'
  },
  FONT_FACES: ['CourgetteRegular', 'OpenSansSemiBold', 'OpenSansRegular']
};