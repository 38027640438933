import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function Linkedin(props) {
  return __jsx("svg", _extends({
    viewBox: "0 0 15.2 14.5"
  }, props), __jsx("path", {
    d: "M15.2 8.9v5.6H12V9.3c0-1.3-.5-2.2-1.6-2.2-.8 0-1.4.5-1.7 1.2-.1.2-.2.5-.2.7v5.5H5.3V4.7h3.3v1.4c.6-1 1.7-1.7 2.9-1.6 2.1 0 3.7 1.4 3.7 4.4zM1.9 0C1-.1.1.6 0 1.5s.6 1.8 1.5 1.9h.4c.9.1 1.8-.6 1.9-1.5C3.9 1 3.2.1 2.3 0h-.4zM.3 14.5h3.3V4.7H.3v9.8z"
  }));
}
export default Linkedin;