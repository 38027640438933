import { colors } from '../../colors';
var staff = {
  bgPrimaryColor: colors.cream,
  bgSecondaryColor: colors.cream,
  bgColorBio: colors.white,
  breadcrumbColor: colors.ash,
  fillColor: colors.coral,
  fillHoverColor: colors.white,
  iconColor: colors.white,
  iconHoverColor: colors.coral,
  iconBorderColor: colors.coral,
  iconBorderHoverColor: 'transparent',
  bladeSubHeadingColor: colors.ash,
  fiveBladeLinkColor: colors.coral,
  textColorDark: colors.ash,
  textColorLight: colors.white
};
export default staff;