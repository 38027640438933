import { colors } from '../../colors';
var gallery = {
  bgColor: colors.palePurple,
  textBg: colors.white,
  textColor: colors.slateGrey,
  modalBgColor: colors.iceBlue,
  closeIconColor: colors.slateGrey,
  modalHeadingColor: colors.slateGrey,
  modalCaptionColor: colors.slateGrey,
  modalParaColor: colors.slateGrey,
  textColorDark: colors.slateGrey,
  textColorLight: colors.white
};
export default gallery;