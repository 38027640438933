import { colors } from '../../colors';
var socialFeed = {
  postTextColor: colors.rose,
  //TODO: Replace with actual color
  primaryColor: colors.rose,
  //TODO: Replace with actual color
  feedBgColor: colors.white,
  headlineUppercase: false,
  subHeadlineUppercase: true
};
export default socialFeed;