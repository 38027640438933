import { colors } from '../../colors';
import Next from '../../../../static/images/icons/Next';
import Prev from '../../../../static/images/icons/Prev';
var carousel = {
  arrowBgColor: colors.cornflowerBlue,
  arrowHoverBgColor: colors.frenchBlue,
  arrowForeColor: colors.white,
  arrowPrevMarginRightVarA: '4px',
  arrowNextMarginLeftVarA: '4px',
  paginationInactiveColor: colors.white,
  paginationActiveColor: colors.cornflowerBlue,
  paginationBorderColor: colors.cornflowerBlue,
  heroPaginationBorder: false,
  noBorderOnDesktopVariationAandB: true,
  heroPaginationInactiveColor: colors.white,
  heroPaginationInactiveColorMobile: colors.cornflowerBlue,
  heroPaginationInactiveColorVariationC: colors.cornflowerBlue,
  heroPaginationActiveColor: colors.cornflowerBlue,
  heroPaginationBorderColor: colors.frenchBlue,
  bgColor: colors.white,
  iconNext: Next,
  iconPrev: Prev
};
export default carousel;