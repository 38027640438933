/**
 * @file Manages the fonts values for the theme Modern V1.
 * @author kkumari
 */export default {
  FONT_MONTSERRAT_REGULAR: 'MontserratRegular, sans-serif' /* change this */,
  FONT_MONTSERRAT_MEDIUM: 'MontserratMedium, sans-serif',
  FONT_LORA_REGULAR: 'LoraRegular, serif',
  FONT_RELEWAY_BOLD: 'RalewayBold, sans-serif',
  FONT_SIZE_BASE: '16px',
  FONT_SIZE_SMALL: '10px',
  FONT_SIZE_DEFAULT: '14px',
  FONT_LETTER_SPACING_DEFAULT: 'normal',
  FONT_LINE_HEIGHT_DEFAULT: '18px',
  FONT_ICONS: 'Arial, sans-serif',
  FONT_WEIGHT_BOLD: 'bold',
  FONT_WEIGHT_NORMAL: 'normal',
  FONT_WEIGHT_600: '600',
  FONT_WEIGHT_500: '500',
  FONT_WEIGHT_300: '300',
  //Mobile values
  //H1
  FONT_SIZE_H1: '36px',
  LINE_HEIGHT_H1: '40px',
  LETTER_SPACING_H1: 'normal',
  //H2
  FONT_SIZE_H2: '30px',
  LINE_HEIGHT_H2: '36px',
  LETTER_SPACING_H2: 'normal',
  //H3
  FONT_SIZE_H3: '24px',
  LINE_HEIGHT_H3: '31px',
  LETTER_SPACING_H3: 'normal',
  //H4
  FONT_SIZE_H4: '16px',
  LINE_HEIGHT_H4: '28px',
  LETTER_SPACING_H4: 'normal',
  //H5
  FONT_SIZE_H5: '12px',
  LINE_HEIGHT_H5: '20px',
  LETTER_SPACING_H5: 'normal',
  //P
  FONT_SIZE_P: '15px',
  LINE_HEIGHT_P: '28px',
  LETTER_SPACING_P: 'normal',
  DESKTOP: {
    //Desktop values
    //H1
    FONT_SIZE_H1: '50px',
    LINE_HEIGHT_H1: '60px',
    LETTER_SPACING_H1: 'normal',
    //H2
    FONT_SIZE_H2: '48px',
    LINE_HEIGHT_H2: '76px',
    LETTER_SPACING_H2: 'normal',
    //H3
    FONT_SIZE_H3: '32px',
    LINE_HEIGHT_H3: '38px',
    LETTER_SPACING_H3: 'normal',
    //H4
    FONT_SIZE_H4: '18px',
    LINE_HEIGHT_H4: '22px',
    LETTER_SPACING_H4: 'normal',
    //H5
    FONT_SIZE_H5: '13px',
    LINE_HEIGHT_H5: '21px',
    LETTER_SPACING_H5: 'normal',
    //P
    FONT_SIZE_P: '17px',
    LINE_HEIGHT_P: '32px',
    LETTER_SPACING_P: 'normal'
  },
  FONT_FACES: ['MontserratRegular', 'MontserratMedium']
};