import { colors } from '../../colors';
var reviewCard = {
  borderRadius: 0,
  reviewLinkColor: colors.deepOrange,
  iconColor: colors.deepOrange,
  reviewLinkHoverColor: colors.scarlet,
  reviewTextColor: colors.black,
  cardBgColor: colors.white,
  bgColor: colors.lightGrey,
  headlineColor: colors.black,
  boxShadow: 'none',
  textColorDark: colors.black,
  textColorLight: colors.white
};
export default reviewCard;