import { colors } from '../../colors';
import fonts from '../../fonts';
var footer = {
  headingFiveFontFamily: fonts.FONT_LATO_REGULAR,
  textColor: colors.white,
  primaryColor: colors.white,
  bgColor: colors.black,
  textHoverColor: colors.white,
  textAddressColor: colors.white,
  textOperationHoursColor: colors.white,
  textPrimaryLinksColor: colors.white,
  textPrimaryLinksHoverColor: colors.white,
  seperatorColor: colors.overcast,
  textTransform: 'uppercase',
  headerTransform: 'uppercase',
  operationHoursContentOpacity: '0.7',
  copyrightFontWeight: 'normal',
  copyrightOpacity: '0.7',
  headingLetterSpacing: '1.98px',
  headingFontSize: '22px',
  textColorDark: colors.black,
  textColorLight: colors.white
};
export default footer;