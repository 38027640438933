import { colors } from '../../colors';
var form = {
  bgColor: colors.whiteish,
  labelColor: colors.graphite,
  errorColor: colors.warningRed,
  borderColor: colors.graphite,
  linkColor: colors.rust,
  textColorDark: colors.graphite,
  textColorLight: colors.white
};
export default form;