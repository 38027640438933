import { colors } from '../../colors';
var accordion = {
  bodyBackgroundColor: colors.equineSnow,
  iconColor: colors.equineIron,
  rowBackgroundColor: colors.overcast,
  textColorDark: colors.equineIron,
  textColorLight: colors.equineSnow,
  makeSummaryHeaderBold: true,
  reduceSideMargins: true,
  ContainerPaddingBottom: '40px',
  reducedFontSize: '24px',
  summaryHeadingFontSize: '18px'
};
export default accordion;