import { colors } from '../../colors';
var service = {
  bgColor: colors.white,
  borderColor: colors.overcast,
  bgColorBlade: colors.white,
  carouselDotColor: colors.orangish,
  linkColor: colors.clearBlue,
  cardRadius: '20px',
  textColorDark: colors.darkNight,
  textColorLight: colors.white
};
export default service;