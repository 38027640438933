import { colors } from '../../colors';
var staff = {
  bgPrimaryColor: colors.darkTaupe,
  bgSecondaryColor: colors.darkTaupe,
  bgColorBio: colors.powder,
  breadcrumbColor: colors.dirtBrown,
  fillColor: colors.white,
  fillHoverColor: colors.white,
  iconColor: colors.marine,
  iconHoverColor: colors.navy,
  iconBorderColor: colors.marine,
  iconBorderHoverColor: colors.navy,
  bladeSubHeadingColor: colors.dirtBrown,
  fiveBladeLinkColor: colors.marine,
  textColorDark: colors.metal,
  textColorLight: colors.white
};
export default staff;