import { colors } from '../../colors';
var footer = {
  textColor: colors.onyx,
  primaryColor: colors.onyx,
  bgColor: colors.whiteish,
  textHoverColor: colors.onyx,
  textAddressColor: colors.onyx,
  textOperationHoursColor: colors.onyx,
  textPrimaryLinksColor: colors.onyx,
  textPrimaryLinksHoverColor: colors.cerulean,
  seperatorColor: colors.overcast,
  textColorDark: colors.onyx,
  textColorLight: colors.white
};
export default footer;