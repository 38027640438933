import { colors } from '../../colors';
var service = {
  bgColor: colors.powder,
  borderColor: colors.overcast,
  bgColorBlade: colors.powder,
  carouselDotColor: colors.marine,
  linkColor: colors.dirtBrown,
  textColorDark: colors.metal,
  textColorLight: colors.white
};
export default service;