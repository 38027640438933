import { colors } from '../../colors';
var fiveCard = {
  textColor: colors.slateGrey,
  hoverColor: colors.neonPurple,
  bgColorPara: colors.white,
  bgColor: colors.white,
  cardBgColorB: colors.neonPurple,
  cardBgColorHoverB: colors.amethyst,
  cardCTAColorB: colors.white,
  cardCTAColorHoverB: colors.neonPurple,
  cardRadius: '20px',
  textBgOpacity: '0.5',
  iconColor: colors.white,
  iconColorHover: colors.neonPurple,
  textBGWidthFull: true
};
export default fiveCard;