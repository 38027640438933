import { colors } from '../../colors';
var header = {
  primaryColor: colors.charcoalGrey,
  hoverColor: colors.stoneTeal,
  borderColor: colors.overcast,
  bgColor: colors.white,
  barColor: colors.charcoalGrey,
  topNavLineHeight: '13px',
  navContactLinkHoverTextDecoration: "underline",
  navUtilityLinkColor: colors.charcoalGrey,
  navUtilityLinkHoverColor: colors.charcoalGrey,
  navUtilityLinkTextDecoration: "none",
  navUtilityLinkHoverTextDecoration: "underline",
  keyCategoryLineHeight: '16px'
};
export default header;