import { colors } from '../../colors';
var form = {
  bgColor: colors.white,
  labelColor: colors.copper,
  errorColor: colors.warningRed,
  borderColor: colors.lead,
  linkColor: colors.copper,
  documentFormBgColor: colors.white,
  textColorDark: colors.lead,
  textColorLight: colors.white
};
export default form;