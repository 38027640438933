import { colors } from '../../colors';
var footer = {
  textColor: colors.white,
  primaryColor: colors.white,
  textPrimaryLinksColor: colors.white,
  bgColor: colors.nvaTeal,
  textHoverColor: colors.white,
  textHoverColorOpacity: '0.2',
  textOperationHoursColor: colors.white,
  textColorDark: colors.ash,
  textColorLight: colors.white,
  textAddressColor: colors.white
};
export default footer;