/**
 * @file Manages the fonts values for the theme Modern V1.
 * @author kkumari
 */export default {
  FONT_QUICKSAND_BOLD: 'QuicksandBold, sans-serif',
  FONT_QUICKSAND_MEDIUM: 'QuicksandMedium, sans-serif',
  FONT_SIZE_BASE: '16px',
  FONT_SIZE_SMALL: '10px',
  FONT_SIZE_DEFAULT: '14px',
  FONT_LETTER_SPACING_DEFAULT: '1.4px',
  FONT_LINE_HEIGHT_DEFAULT: '18px',
  FONT_ICONS: 'Arial, sans-serif',
  FONT_WEIGHT_BOLD: 'bold',
  FONT_WEIGHT_NORMAL: 'normal',
  FONT_WEIGHT_500: '500',
  //Mobile values
  //H1
  FONT_SIZE_H1: '40px',
  LINE_HEIGHT_H1: '46px',
  LETTER_SPACING_H1: '0.4px',
  //H2
  FONT_SIZE_H2: '28px',
  LINE_HEIGHT_H2: '32px',
  LETTER_SPACING_H2: '1.68px',
  //H3
  FONT_SIZE_H3: '22px',
  LINE_HEIGHT_H3: '42px',
  LETTER_SPACING_H3: '0.44px',
  //H4
  FONT_SIZE_H4: '18px',
  LINE_HEIGHT_H4: '20px',
  LETTER_SPACING_H4: '0.36px',
  //H5
  FONT_SIZE_H5: '13px',
  LINE_HEIGHT_H5: '17px',
  LETTER_SPACING_H5: '0.13px',
  //P
  FONT_SIZE_P: '16px',
  LINE_HEIGHT_P: '28px',
  LETTER_SPACING_P: '0.32px',
  DESKTOP: {
    //Desktop values
    //H1
    FONT_SIZE_H1: '58px',
    LINE_HEIGHT_H1: '68px',
    LETTER_SPACING_H1: '0.58px',
    //H2
    FONT_SIZE_H2: '36px',
    LINE_HEIGHT_H2: '46px',
    LETTER_SPACING_H2: '0.72px',
    //H3
    FONT_SIZE_H3: '28px',
    LINE_HEIGHT_H3: '40px',
    LETTER_SPACING_H3: '0.28px',
    //H4
    FONT_SIZE_H4: '18px',
    LINE_HEIGHT_H4: '23px',
    LETTER_SPACING_H4: '0.54px',
    //H5
    FONT_SIZE_H5: '13px',
    LINE_HEIGHT_H5: '16px',
    LETTER_SPACING_H5: '0.26px',
    //P
    FONT_SIZE_P: '16px',
    LINE_HEIGHT_P: '28px',
    LETTER_SPACING_P: '0.32px'
  },
  FONT_FACES: ['QuicksandBold', 'QuicksandMedium']
};