import { colors } from '../../colors';
var gallery = {
  bgColor: colors.whiteish,
  textBg: colors.white,
  textColor: colors.onyx,
  modalBgColor: colors.white,
  closeIconColor: colors.onyx,
  modalHeadingColor: colors.onyx,
  modalCaptionColor: colors.onyx,
  modalParaColor: colors.onyx,
  textColorDark: colors.onyx,
  textColorLight: colors.white
};
export default gallery;