import { colors } from '../../colors';
var blogList = {
  borderColor: colors.overcast,
  bgColor: colors.white,
  breadcrumbColor: colors.neonPurple,
  subHeadingColor: colors.slateGrey,
  headingColor: colors.slateGrey,
  paraColor: colors.slateGrey,
  blogTileImageRadius: '20px',
  filterTextColor: colors.slateGrey
};
export default blogList;