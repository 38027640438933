import { colors } from '../../colors';
var blogList = {
  borderColor: colors.overcast,
  bgColor: colors.white,
  breadcrumbColor: colors.copper,
  subHeadingColor: colors.copper,
  headingColor: colors.lead,
  paraColor: colors.lead,
  filterTextColor: colors.lead
};
export default blogList;