import { colors } from '../../colors';
import fonts from '../../fonts';
var footer = {
  headingFiveFontFamily: fonts.FONT_OPENSANS_REGULAR,
  textColor: colors.white,
  primaryColor: colors.white,
  bgColor: colors.black,
  textHoverColor: colors.white,
  textAddressColor: colors.white,
  textOperationHoursColor: colors.white,
  textPrimaryLinksColor: colors.white,
  textPrimaryLinksHoverColor: colors.mutedBlue,
  seperatorColor: colors.overcast,
  textTransform: 'uppercase',
  headerTransform: 'uppercase',
  headingLineHeight: '37px',
  operationHoursContentOpacity: '0.7',
  copyrightOpacity: '0.7',
  textColorDark: colors.black,
  textColorLight: colors.white
};
export default footer;