import { colors } from '../../colors';
var blogList = {
  borderColor: colors.overcast,
  bgColor: colors.powder,
  breadcrumbColor: colors.dirtBrown,
  subHeadingColor: colors.metal,
  headingColor: colors.metal,
  paraColor: colors.metal,
  filterTextColor: colors.metal
};
export default blogList;