import { colors } from '../../colors';
var reviewCard = {
  borderRadius: 0,
  reviewLinkColor: colors.pinkishRed,
  iconColor: colors.brightGreen,
  reviewLinkHoverColor: colors.rouge,
  reviewTextColor: colors.black,
  cardBgColor: colors.paleGrey,
  bgColor: colors.white,
  headlineColor: colors.black,
  boxShadow: 'none',
  cardRadius: '20px',
  textColorDark: colors.black,
  textColorLight: colors.white
};
export default reviewCard;