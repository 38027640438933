import { colors } from '../../colors';
var form = {
  bgColor: colors.powder,
  labelColor: colors.metal,
  errorColor: colors.warningRed,
  borderColor: colors.metal,
  linkColor: colors.dirtBrown,
  documentFormBgColor: colors.powder,
  textColorDark: colors.metal,
  textColorLight: colors.white
};
export default form;