import { colors } from '../../colors';
var fiveCard = {
  textColor: colors.equineIron,
  hoverColor: colors.equineShadow,
  bgColorPara: colors.equineSnow,
  bgColor: colors.equineSnow,
  cardBgColorB: colors.equineSnow,
  cardBgColorHoverB: colors.equineStone,
  cardCTAColorB: colors.equineStone,
  cardCTAColorHoverB: colors.equineSnow,
  iconColor: colors.equineStone,
  iconColorHover: colors.equineSnow,
  textBgOpacity: 0.2,
  textBGWidthFull: true,
  removeSpaceBetween: true,
  containerWidthFull: true,
  centerAnchorText: true,
  removeAnchorTextArrow: true /* remove this after getting already existing theme.displayNoRightArrow to work */,
  slickParaHeightIncreaseHover: true,
  replaceContainerPaddingWithMargin: true,
  containerMobileMargin: '0 0 80px',
  containerDesktopMargin: '0',
  useEquineRenditions: true
};
export default fiveCard;