import { css } from 'styled-components';
var textures = [{
  key: 'leavesOrganicStone',
  label: 'Leaves Organic Stone',
  style: css(["background-color:rgb(218,213,198);background-image:url('/static/images/textures/EarthyV3/leavesOrganicStone.svg');background-repeat:repeat;background-size:476px 476px;"])
}, {
  key: 'leavesOrganicSalmon',
  label: 'Leaves Organic Salmon',
  style: css(["background-color:rgb(235,165,145);background-image:url('/static/images/textures/EarthyV3/leavesOrganicSalmon.svg');background-repeat:repeat;background-size:476px 476px;"])
}, {
  key: 'leavesOrganicYellow',
  label: 'Leaves Organic Yellow',
  style: css(["background-color:rgb(229,187,100);background-image:url('/static/images/textures/EarthyV3/leavesOrganicYellow.svg');background-repeat:repeat;background-size:476px 476px;"])
}, {
  key: 'waveStone',
  label: 'Wave Stone',
  style: css(["background-color:rgb(218,213,198);background-image:url('/static/images/textures/EarthyV3/waveStone.svg');background-repeat:repeat;background-size:500px 500px;"])
}, {
  key: 'waveSalmon',
  label: 'Wave Salmon',
  style: css(["background-color:rgb(235,165,145);background-image:url('/static/images/textures/EarthyV3/waveSalmon.svg');background-repeat:repeat;background-size:500px 500px;"])
}, {
  key: 'waveYellow',
  label: 'Wave Yellow',
  style: css(["background-color:rgb(229,187,100);background-image:url('/static/images/textures/EarthyV3/waveYellow.svg');background-repeat:repeat;background-size:500px 500px;"])
}, {
  key: 'woodgrainStone',
  label: 'Woodgrain Stone',
  style: css(["background-color:rgb(218,213,198);background-image:url('/static/images/textures/EarthyV3/woodgrainStone.svg');background-repeat:repeat;background-size:500px 501px;"])
}, {
  key: 'woodgrainSalmon',
  label: 'Woodgrain Salmon',
  style: css(["background-color:rgb(235,165,145);background-image:url('/static/images/textures/EarthyV3/woodgrainSalmon.svg');background-repeat:repeat;background-size:500px 501px;"])
}, {
  key: 'woodgrainYellow',
  label: 'Woodgrain Yellow',
  style: css(["background-color:rgb(229,187,100);background-image:url('/static/images/textures/EarthyV3/woodgrainYellow.svg');background-repeat:repeat;background-size:500px 501px;"])
}, {
  key: 'leavesGeometricStone',
  label: 'Leaves Geometric Stone',
  style: css(["background-color:rgb(218,213,198);background-image:url('/static/images/textures/EarthyV3/leavesGeometricStone.svg');background-repeat:repeat;background-size:400px 402px;"])
}, {
  key: 'leavesGeometricSalmon',
  label: 'Leaves Geometric Salmon',
  style: css(["background-color:rgb(235,165,145);background-image:url('/static/images/textures/EarthyV3/leavesGeometricSalmon.svg');background-repeat:repeat;background-size:400px 402px;"])
}, {
  key: 'leavesGeometricYellow',
  label: 'Leaves Geometric Yellow',
  style: css(["background-color:rgb(229,187,100);background-image:url('/static/images/textures/EarthyV3/leavesGeometricYellow.svg');background-repeat:repeat;background-size:400px 402px;"])
}];
export default textures;