import { colors } from '../../colors';
import Next from '../../../../static/images/icons/Next';
import Prev from '../../../../static/images/icons/Prev';
var carousel = {
  arrowBgColor: colors.neonPurple,
  arrowHoverBgColor: colors.amethyst,
  arrowForeColor: colors.white,
  arrowPrevMarginRight: '4px',
  arrowNextMarginLeft: '4px',
  highlightColor: colors.slateGrey,
  paginationInactiveColor: colors.white,
  paginationActiveColor: colors.neonPurple,
  paginationBorderColor: colors.neonPurple,
  heroPaginationBorder: false,
  noBorderOnDesktopVariationAandB: true,
  heroPaginationInactiveColor: colors.white,
  heroPaginationInactiveColorMobile: colors.neonPurple,
  heroPaginationInactiveColorVariationC: colors.neonPurple,
  heroPaginationActiveColor: colors.neonPurple,
  heroPaginationBorderColor: colors.amethyst,
  bgColor: colors.white,
  iconNext: Next,
  iconPrev: Prev
};
export default carousel;