/**
 * @file Manages the fonts values for the theme Corporate.
 * @author shivam
 */export default {
  FONT_CALIBRI_REGULAR: 'Calibri, sans-serif',
  FONT_CALIBRI_BOLD: 'CalibriBold, sans-serif',
  FONT_CALIBRI_BOLD_ITALIC: 'CalibriBoldItalic, sans-serif',
  FONT_CALIBRI_ITALIC: 'CalibriItalic, sans-serif',
  FONT_GOUDY_REGULAR: 'GoudyOldStyleMT, serif',
  // GoudyOldSty-Reg = GoudyOldStyleMT
  FONT_GOUDY_BOLD: 'GoudyOldStyleBold, serif',
  // GoudyOldSty-Bol = GoudyOlStBTWXX-Bold
  FONT_GOUDY_ITALIC: 'GoudyOldStyleMTItalic, serif',
  FONT_SIZE_BASE: '16px',
  FONT_SIZE_SMALL: '10px',
  FONT_SIZE_DEFAULT: '16px',
  FONT_SIZE_TERTIARY: '18px',
  FONT_LETTER_SPACING_DEFAULT: '1.9px',
  FONT_LINE_HEIGHT_DEFAULT: '18px',
  FONT_ICONS: 'Arial, sans-serif',
  FONT_WEIGHT_BOLD: 'bold',
  FONT_WEIGHT_NORMAL: 'normal',
  FONT_WEIGHT_500: '500',
  //Mobile values
  //H1
  FONT_SIZE_H1: '50px',
  LINE_HEIGHT_H1: '64px',
  LETTER_SPACING_H1: '0.5px',
  //H2
  FONT_SIZE_H2: '32px',
  LINE_HEIGHT_H2: '34px',
  LETTER_SPACING_H2: 'normal',
  //H3
  FONT_SIZE_H3: '32px',
  LINE_HEIGHT_H3: '34px',
  LETTER_SPACING_H3: 'normal',
  //H4
  FONT_SIZE_H4: '18px',
  LINE_HEIGHT_H4: '28px',
  LETTER_SPACING_H4: '0.18px',
  //H5
  FONT_SIZE_H5: '12px',
  LINE_HEIGHT_H5: '20px',
  LETTER_SPACING_H5: '0.36px',
  //P
  FONT_SIZE_P: '15px',
  LINE_HEIGHT_P: '25px',
  LETTER_SPACING_P: 'normal',
  DESKTOP: {
    //Desktop values
    //H1
    FONT_SIZE_H1: '62px',
    LINE_HEIGHT_H1: '64px',
    LETTER_SPACING_H1: '0.62px',
    //H2
    FONT_SIZE_H2: '40px',
    LINE_HEIGHT_H2: '46px',
    LETTER_SPACING_H2: '0.4px',
    //H3
    FONT_SIZE_H3: '28px',
    LINE_HEIGHT_H3: '32px',
    LETTER_SPACING_H3: '0.28px',
    //H4
    FONT_SIZE_H4: '20px',
    LINE_HEIGHT_H4: '26px',
    LETTER_SPACING_H4: '0.2px',
    //H5
    FONT_SIZE_H5: '14px',
    LINE_HEIGHT_H5: '20px',
    LETTER_SPACING_H5: '0.42px',
    //P
    FONT_SIZE_P: '16px',
    LINE_HEIGHT_P: '28px',
    LETTER_SPACING_P: '0.32px'
  },
  FONT_FACES: ['Calibri', 'CalibriBold', 'CalibriBoldItalic', 'CalibriItalic', 'GoudyOldStyleMT', 'GoudyOldStyleMTItalic', 'GoudyOldStyleBold']
};