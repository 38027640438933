import { colors } from '../../colors';
var staff = {
  bgPrimaryColor: colors.lightPink,
  bgSecondaryColor: colors.lightPink,
  bgColorBio: colors.white,
  breadcrumbColor: colors.copper,
  fillColor: colors.white,
  fillHoverColor: colors.white,
  iconColor: colors.cornflowerBlue,
  iconHoverColor: colors.frenchBlue,
  iconBorderColor: colors.cornflowerBlue,
  iconBorderHoverColor: colors.frenchBlue,
  bladeSubHeadingColor: colors.copper,
  fiveBladeLinkColor: colors.cornflowerBlue,
  textColorDark: colors.lead,
  textColorLight: colors.white
};
export default staff;