import { colors } from '../../colors';
var threeTile = {
  borderColor: colors.overcast,
  headingColor: colors.graphite,
  paraColor: colors.graphite,
  subHeadingColor: colors.graphite,
  bgColor: colors.white,
  ctaColor: colors.petsuitesPurple,
  cardRadius: '20px',
  textColorDark: colors.graphite,
  textColorLight: colors.white,
  cardBgColorVariationC: colors.white
};
export default threeTile;