import { colors } from '../../colors';
var reviewCard = {
  borderRadius: 0,
  reviewLinkColor: colors.equineStone,
  iconColor: colors.equineStone,
  reviewLinkHoverColor: colors.equineIron,
  reviewTextColor: colors.equineShadow,
  cardBgColor: colors.equineSnow,
  bgColor: colors.equineSnow,
  headlineColor: colors.equineShadow,
  boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
  textColorDark: colors.equineShadow,
  textColorLight: colors.equineSnow,
  useAlternateFontAttributes: true,
  mobileMaxWidth: '100%',
  tabletCardWidth: '400px',
  reviewerContainerMobileTopPadding: '20px',
  containerMarginBottom: '25px',
  containerMarginRight: '25px',
  hideReviewerPicture: true,
  reviewContainerPadding: '40px 20px 80px'
};
export default reviewCard;