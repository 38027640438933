import { colors } from '../../colors';
var button = {
  //Hero button
  linkColorHero: colors.white,
  linkColorHeroHover: colors.white,
  backgroundColorHero: colors.cornflowerBlue,
  backgroundColorHoverHero: colors.frenchBlue,
  borderRadiusHero: '100px',
  linkLineHeightHero: '30px',
  heroButtonPadding: '20px 35px',
  //primary button
  linkColorPrimary: colors.white,
  linkColorPrimaryHover: colors.white,
  backgroundColorPrimary: colors.cornflowerBlue,
  backgroundColorHoverPrimary: colors.frenchBlue,
  borderRadiusPrimary: '100px',
  //secondary button
  linkColorSecondary: colors.cornflowerBlue,
  backgroundColorSecondary: 'transparent',
  backgroundColorHoverSecondary: 'transparent',
  borderRadiusSecondary: '0',
  linkColorSecondaryHover: colors.frenchBlue,
  borderColorSecondary: 'transparent',
  borderColorSecondaryHover: 'transparent',
  //secondary 'Light' button
  hasAlternateLightSecondaryAnchor: true,
  linkColorAltSecondary: colors.white
};
export default button;