import { colors } from '../../colors';
var gallery = {
  bgColor: colors.pale,
  textBg: colors.white,
  textColor: colors.charcoalGrey,
  modalBgColor: colors.white,
  closeIconColor: colors.charcoalGrey,
  modalHeadingColor: colors.charcoalGrey,
  modalCaptionColor: colors.charcoalGrey,
  modalParaColor: colors.charcoalGrey,
  textColorDark: colors.charcoalGrey,
  textColorLight: colors.white
};
export default gallery;