import { colors } from '../../colors';
var blogList = {
  borderColor: colors.overcast,
  bgColor: colors.offWhite,
  breadcrumbColor: colors.dustyTeal,
  subHeadingColor: colors.charcoalGrey,
  headingColor: colors.charcoalGrey,
  paraColor: colors.charcoalGrey,
  filterTextColor: colors.charcoalGrey
};
export default blogList;