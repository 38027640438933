import { colors } from '../../colors';
var fiveCard = {
  textColor: colors.black,
  hoverColor: colors.pinkishRed,
  bgColorPara: colors.white,
  bgColor: colors.paleSkyBlue,
  cardBgColorB: colors.white,
  cardBgColorHoverB: colors.brightGreen,
  cardCTAColorB: colors.black,
  cardCTAColorHoverB: colors.black,
  cardRadius: '20px',
  textBgOpacity: '0.5',
  iconColor: colors.brightGreen,
  iconColorHover: colors.white,
  textBGWidthFull: true
};
export default fiveCard;