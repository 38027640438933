import { colors } from '../../colors';
var fiftyFiftyCard = {
  bgColorLight: colors.equineSnow,
  bgColorDark: colors.equineShadow,
  textColorDark: colors.equineShadow,
  textColorLight: colors.equineSnow,
  equalHalves: true,
  equalCrossPadding: true,
  removeContainerPadding: true,
  removeImagePadding: true,
  imageFullHeight: true,
  fillImage: false,
  paddingOnTextHalf: '60px',
  paragraphPadding: '0',
  alternateMobileImagePadding: '15px'
};
export default fiftyFiftyCard;