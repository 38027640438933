import { colors } from '../../colors';
var service = {
  bgColor: colors.nvaLightGrey,
  borderColor: colors.overcast,
  bgColorBlade: colors.nvaTeal,
  carouselDotColor: colors.nvaLightTeal,
  linkColor: colors.rose,
  textColorDark: colors.smoke,
  textColorLight: colors.white
};
export default service;