import { colors } from '../../colors';
var fiveCard = {
  textColor: colors.onyx,
  hoverColor: colors.azure,
  bgColorPara: colors.white,
  bgColor: colors.white,
  cardBgColorB: colors.white,
  cardBgColorHoverB: colors.azure,
  cardCTAColorB: colors.onyx,
  cardCTAColorHoverB: colors.black,
  iconColor: colors.azure,
  iconColorHover: colors.white
};
export default fiveCard;