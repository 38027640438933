import { css } from 'styled-components';
var textures = [{
  key: 'leavesOrganicCream',
  label: 'Leaves Organic Cream',
  style: css(["background-color:rgb(255,247,242);background-image:url('/static/images/textures/EarthyV2/leavesOrganicCream.svg');background-repeat:repeat;background-size:476px 476px;"])
}, {
  key: 'leavesOrganicNavy',
  label: 'Leaves Organic Navy',
  style: css(["background-color:rgb(45,75,93);background-image:url('/static/images/textures/EarthyV2/leavesOrganicNavy.svg');background-repeat:repeat;background-size:476px 476px;"])
}, {
  key: 'leavesOrganicBrown',
  label: 'Leaves Organic Brown',
  style: css(["background-color:rgb(114,90,72);background-image:url('/static/images/textures/EarthyV2/leavesOrganicBrown.svg');background-repeat:repeat;background-size:476px 476px;"])
}, {
  key: 'waveCream',
  label: 'Wave Cream',
  style: css(["background-color:rgb(255,247,242);background-image:url('/static/images/textures/EarthyV2/waveCream.svg');background-repeat:repeat;background-size:500px 500px;"])
}, {
  key: 'waveNavy',
  label: 'Wave Navy',
  style: css(["background-color:rgb(45,75,93);background-image:url('/static/images/textures/EarthyV2/waveNavy.svg');background-repeat:repeat;background-size:500px 500px;"])
}, {
  key: 'waveBrown',
  label: 'Wave Brown',
  style: css(["background-color:rgb(114,90,72);background-image:url('/static/images/textures/EarthyV2/waveBrown.svg');background-repeat:repeat;background-size:500px 500px;"])
}, {
  key: 'woodgrainCream',
  label: 'Woodgrain Cream',
  style: css(["background-color:rgb(255,247,242);background-image:url('/static/images/textures/EarthyV2/leavesOrganicBrown.svg');background-repeat:repeat;background-size:500px 501px;"])
}, {
  key: 'woodgrainNavy',
  label: 'Woodgrain Navy',
  style: css(["background-color:rgb(45,75,93);background-image:url('/static/images/textures/EarthyV2/woodgrainNavy.svg');background-repeat:repeat;background-size:500px 501px;"])
}, {
  key: 'woodgrainBrown',
  label: 'Woodgrain Brown',
  style: css(["background-color:rgb(114,90,72);background-image:url('/static/images/textures/EarthyV2/woodgrainBrown.svg');background-repeat:repeat;background-size:500px 501px;"])
}, {
  key: 'leavesGeometricCream',
  label: 'Leaves Geometric Cream',
  style: css(["background-color:rgb(255,247,242);background-image:url('/static/images/textures/EarthyV2/leavesGeometricCream.svg');background-repeat:repeat;background-size:400px 402px;"])
}, {
  key: 'leavesGeometricNavy',
  label: 'Leaves Geometric Navy',
  style: css(["background-color:rgb(45,75,93);background-image:url('/static/images/textures/EarthyV2/leavesGeometricNavy.svg');background-repeat:repeat;background-size:400px 402px;"])
}, {
  key: 'leavesGeometricBrown',
  label: 'Leaves Geometric Brown',
  style: css(["background-color:rgb(114,90,72);background-image:url('/static/images/textures/EarthyV2/leavesGeometricBrown.svg');background-repeat:repeat;background-size:400px 402px;"])
}];
export default textures;