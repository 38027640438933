import { colors } from '../../colors';
var gallery = {
  bgColor: colors.whiteish,
  textBg: colors.duskBlue,
  textColor: colors.white,
  modalBgColor: colors.whiteish,
  closeIconColor: colors.graphite,
  modalHeadingColor: colors.graphite,
  modalCaptionColor: colors.graphite,
  modalParaColor: colors.graphite,
  textColorDark: colors.graphite,
  textColorLight: colors.white
};
export default gallery;