import { colors } from '../../colors';
var button = {
  //Hero button
  linkColorHero: colors.white,
  linkColorHeroHover: colors.white,
  backgroundColorHero: colors.dustyTeal,
  backgroundColorHoverHero: colors.stoneTeal,
  borderRadiusHero: '100px',
  linkLineHeightHero: '30px',
  heroButtonPadding: '20px 35px',
  //primary button
  linkColorPrimary: colors.white,
  linkColorPrimaryHover: colors.white,
  backgroundColorPrimary: colors.dustyTeal,
  backgroundColorHoverPrimary: colors.stoneTeal,
  borderRadiusPrimary: '100px',
  //secondary button
  linkColorSecondary: colors.offWhite,
  backgroundColorSecondary: colors.dustyTeal,
  backgroundColorHoverSecondary: colors.stoneTeal,
  borderRadiusSecondary: '200px',
  linkColorSecondaryHover: colors.offWhite,
  borderColorSecondary: 'transparent',
  borderColorSecondaryHover: 'transparent'
};
export default button;