import { colors } from '../../colors';
var fiveCard = {
  textColor: colors.darkNight,
  hoverColor: colors.darkNight,
  bgColorPara: colors.ivory,
  bgColor: colors.ivory,
  cardBgColorB: colors.white,
  cardBgColorHoverB: colors.orangish,
  cardCTAColorB: colors.black,
  cardCTAColorHoverB: colors.black,
  cardRadius: '20px',
  textBgOpacity: '0.5',
  iconColor: colors.orangish,
  iconColorHover: colors.white
};
export default fiveCard;