import { colors } from '../../colors';
var blogList = {
  borderColor: colors.overcast,
  bgColor: colors.white,
  breadcrumbColor: colors.clearBlue,
  subHeadingColor: colors.clearBlue,
  headingColor: colors.darkNight,
  paraColor: colors.darkNight,
  blogTileImageRadius: '20px',
  filterTextColor: colors.darkNight
};
export default blogList;