import { colors } from '../../colors';
var header = {
  primaryColor: colors.ash,
  hoverColor: colors.rustRed,
  borderColor: colors.overcast,
  bgColor: colors.white,
  barColor: colors.ash,
  navContactLinkHoverTextDecoration: "underline",
  navUtilityLinkColor: colors.ash,
  navUtilityLinkHoverColor: colors.ash,
  navUtilityLinkTextDecoration: "none",
  navUtilityLinkHoverTextDecoration: "underline",
  topNavLineHeight: '15px'
};
export default header;