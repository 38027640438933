import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function Next2(props) {
  return __jsx("svg", _extends({
    width: 17,
    height: 27,
    viewBox: "0 0 17 27"
  }, props), __jsx("path", {
    d: "M.6 26.493a2.088 2.088 0 002.729 0l13.105-11.754a1.608 1.608 0 000-2.448L3.325.537A2.077 2.077 0 001.443.055 1.84 1.84 0 00.061 1.3a1.617 1.617 0 00.54 1.688l11.745 10.527L.6 24.044a1.646 1.646 0 00-.568 1.223A1.645 1.645 0 00.6 26.493z",
    fill: "#fff"
  }));
}
export default Next2;