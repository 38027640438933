import { colors } from '../../colors';
var form = {
  bgColor: colors.whiteish,
  labelColor: colors.onyx,
  errorColor: colors.warningRed,
  borderColor: colors.onyx,
  linkColor: colors.cerulean,
  textColorDark: colors.onyx,
  textColorLight: colors.white
};
export default form;