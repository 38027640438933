import { colors } from '../../colors';
var staff = {
  bgPrimaryColor: colors.pale,
  bgSecondaryColor: colors.pale,
  bgColorBio: colors.white,
  breadcrumbColor: colors.dustyTeal,
  fillColor: colors.white,
  fillHoverColor: colors.white,
  iconColor: colors.dustyTeal,
  iconHoverColor: colors.stoneTeal,
  iconBorderColor: 'transparent',
  iconBorderHoverColor: 'transparent',
  bladeSubHeadingColor: colors.charcoalGrey,
  fiveBladeLinkColor: colors.dustyTeal,
  textColorDark: colors.charcoalGrey,
  textColorLight: colors.white
};
export default staff;