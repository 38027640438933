import { colors } from '../../colors';
var threeTile = {
  borderColor: colors.overcast,
  headingColor: colors.equineIron,
  paraColor: colors.equineIron,
  subHeadingColor: colors.equineStone,
  bgColor: colors.equineSnow,
  ctaColor: colors.equineStone,
  textColorDark: colors.equineIron,
  textColorLight: colors.equineSnow,
  cardBgColorVariationC: colors.equineSnow
};
export default threeTile;