import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function Prev2(props) {
  return __jsx("svg", _extends({
    width: 17,
    height: 27,
    viewBox: "0 0 17 27"
  }, props), __jsx("path", {
    d: "M16.4.507a2.088 2.088 0 00-2.729 0L.566 12.261a1.608 1.608 0 000 2.448l13.109 11.754a2.077 2.077 0 001.882.482 1.84 1.84 0 001.382-1.245 1.617 1.617 0 00-.54-1.688L4.654 13.485 16.4 2.956a1.646 1.646 0 00.568-1.223A1.645 1.645 0 0016.4.507z",
    fill: "#fff"
  }));
}
export default Prev2;