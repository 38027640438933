import { colors } from '../../colors';
var footer = {
  textColor: colors.overcast,
  primaryColor: colors.white,
  bgColor: colors.pine,
  textHoverColor: colors.white,
  textAddressColor: colors.overcast,
  textOperationHoursColor: colors.overcast,
  textPrimaryLinksColor: colors.overcast,
  textPrimaryLinksHoverColor: colors.militaryGreen,
  seperatorColor: colors.overcast,
  textTransform: 'uppercase',
  primaryLinkLetterSpacing: '2.6px',
  tertiaryLinkFontWeight: 'normal',
  copyrightFontWeight: 'normal',
  copyRightTextTransform: 'uppercase',
  teriaryTextTransform: 'uppercase',
  textColorLight: colors.white,
  textColorDark: colors.ash
};
export default footer;