import { colors } from '../../colors';
var header = {
  primaryColor: colors.slateGreen,
  hoverColor: colors.militaryGreen,
  borderColor: colors.overcast,
  bgColor: colors.alabaster,
  barColor: colors.pine,
  navContactLinkHoverTextDecoration: "underline",
  navUtilityLinkColor: colors.pine,
  navUtilityLinkHoverColor: colors.pine,
  navUtilityLinkTextDecoration: "none",
  navUtilityLinkHoverTextDecoration: "underline",
  topNavLineHeight: '13px'
};
export default header;