import { colors } from '../../colors';
var service = {
  bgColor: colors.white,
  borderColor: colors.overcast,
  bgColorBlade: colors.white,
  carouselDotColor: colors.black,
  linkColor: colors.red,
  textColorDark: colors.black,
  textColorLight: colors.white
};
export default service;