import { colors } from '../../colors';
import { transparentize } from 'polished';
var reviewCard = {
  borderRadius: 0,
  reviewLinkColor: colors.rust,
  iconColor: colors.rust,
  reviewLinkHoverColor: colors.deepRed,
  reviewTextColor: colors.graphite,
  cardBgColor: colors.white,
  bgColor: colors.whiteish,
  headlineColor: colors.graphite,
  boxShadow: "0 10px 30px 0 ".concat(transparentize(0.5, '#000')),
  textColorDark: colors.graphite,
  textColorLight: colors.white
};
export default reviewCard;