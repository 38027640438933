import { colors } from '../../colors';
var header = {
  primaryColor: colors.nvaBlack,
  hoverColor: colors.nvaDarkTeal,
  borderColor: colors.overcast,
  bgColor: colors.white,
  barColor: colors.nvaBlack,
  navContactLinkTextDecoration: "underline",
  navContactLinkHoverTextDecoration: "underline",
  navUtilityLinkColor: colors.nvaBlack,
  navUtilityLinkHoverColor: colors.nvaTeal,
  navUtilityLinkTextDecoration: "underline",
  navUtilityLinkHoverTextDecoration: "underline",
  navCategoryLinkHoverColor: colors.nvaTeal,
  topNavLineHeight: '20px'
};
export default header;