import { colors } from '../../colors';
var form = {
  bgColor: colors.nvaTeal,
  labelColor: colors.white,
  errorColor: colors.warningRed,
  borderColor: colors.white,
  linkColor: colors.dustyTeal,
  documentFormBgColor: colors.nvaTeal,
  linkHoverColor: colors.nvaDarkTeal
};
export default form;