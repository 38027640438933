import { css } from 'styled-components';
var textures = [{
  key: 'dogLavender',
  label: 'Dog Lavender',
  style: css(["background-color:rgb(240,236,253);background-image:url('/static/images/textures/PlayfulV1/dogLavender.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catLavender',
  label: 'Cat Lavender',
  style: css(["background-color:rgb(240,236,253);background-image:url('/static/images/textures/PlayfulV1/catLavender.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesLavender',
  label: 'Bones Lavender',
  style: css(["background-color:rgb(240,236,253);background-image:url('/static/images/textures/PlayfulV1/bonesLavender.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricLavender',
  label: 'Geometric Lavender',
  style: css(["background-color:rgb(240,236,253);background-image:url('/static/images/textures/PlayfulV1/geometricLavender.svg');background-repeat:repeat;background-size:846px 594px;"])
}, {
  key: 'dogLightBlue',
  label: 'Dog Light Blue',
  style: css(["background-color:rgb(222,246,255);background-image:url('/static/images/textures/PlayfulV1/dogLightBlue.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catLightBlue',
  label: 'Cat Light Blue',
  style: css(["background-color:rgb(222,246,255);background-image:url('/static/images/textures/PlayfulV1/catLightBlue.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesLightBlue',
  label: 'Bones Light Blue',
  style: css(["background-color:rgb(222,246,255);background-image:url('/static/images/textures/PlayfulV1/bonesLightBlue.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricLightBlue',
  label: 'Geometric Light Blue',
  style: css(["background-color:rgb(222,246,255);background-image:url('/static/images/textures/PlayfulV1/geometricLightBlue.svg');background-repeat:repeat;background-size:846px 594px;"])
}, {
  key: 'dogLightYellow',
  label: 'Dog Light Yellow',
  style: css(["background-color:rgb(254,232,188);background-image:url('/static/images/textures/PlayfulV1/dogLightYellow.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catLightYellow',
  label: 'Cat Light Yellow',
  style: css(["background-color:rgb(254,232,188);background-image:url('/static/images/textures/PlayfulV1/catLightYellow.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesLightYellow',
  label: 'Bones Light Yellow',
  style: css(["background-color:rgb(254,232,188);background-image:url('/static/images/textures/PlayfulV1/bonesLightYellow.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricLightYellow',
  label: 'Geometric Light Yellow',
  style: css(["background-color:rgb(254,232,188);background-image:url('/static/images/textures/PlayfulV1/geometricLightYellow.svg');background-repeat:repeat;background-size:846px 594px;"])
}];
export default textures;