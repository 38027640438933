import { css } from 'styled-components';
var textures = [{
  key: 'dogPink',
  label: 'Dog Pink',
  style: css(["background-color:rgb(248,175,188);background-image:url('/static/images/textures/PlayfulV2/dogPink.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catPink',
  label: 'Cat Pink',
  style: css(["background-color:rgb(248,175,188);background-image:url('/static/images/textures/PlayfulV2/catPink.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesPink',
  label: 'Bones Pink',
  style: css(["background-color:rgb(248,175,188);background-image:url('/static/images/textures/PlayfulV2/bonesPink.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricPink',
  label: 'Geometric Pink',
  style: css(["background-color:rgb(248,175,188);background-image:url('/static/images/textures/PlayfulV2/geometricPink.svg');background-repeat:repeat;background-size:846px 594px;"])
}, {
  key: 'dogPeach',
  label: 'Dog Peach',
  style: css(["background-color:rgb(253,203,171);background-image:url('/static/images/textures/PlayfulV2/dogPeach.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catPeach',
  label: 'Cat Peach',
  style: css(["background-color:rgb(253,203,171);background-image:url('/static/images/textures/PlayfulV2/catPeach.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesPeach',
  label: 'Bones Peach',
  style: css(["background-color:rgb(253,203,171);background-image:url('/static/images/textures/PlayfulV2/bonesPeach.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricPeach',
  label: 'Geometric Peach',
  style: css(["background-color:rgb(253,203,171);background-image:url('/static/images/textures/PlayfulV2/geometricPeach.svg');background-repeat:repeat;background-size:846px 594px;"])
}, {
  key: 'dogBabyBlue',
  label: 'Dog Baby Blue',
  style: css(["background-color:rgb(186,234,255);background-image:url('/static/images/textures/PlayfulV2/dogBabyBlue.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catBabyBlue',
  label: 'Cat Baby Blue',
  style: css(["background-color:rgb(186,234,255);background-image:url('/static/images/textures/PlayfulV2/catBabyBlue.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesBabyBlue',
  label: 'Bones Baby Blue',
  style: css(["background-color:rgb(186,234,255);background-image:url('/static/images/textures/PlayfulV2/bonesBabyBlue.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricBabyBlue',
  label: 'Geometric Baby Blue',
  style: css(["background-color:rgb(186,234,255);background-image:url('/static/images/textures/PlayfulV2/geometricBabyBlue.svg');background-repeat:repeat;background-size:846px 594px;"])
}];
export default textures;