import { colors } from '../../colors';
var footer = {
  textColor: colors.equineSnow,
  primaryColor: colors.equineSnow,
  bgColor: colors.equineShadow,
  textHoverColor: colors.equineSnow,
  textAddressColor: colors.equineSnow,
  textAddressTextDecorationColor: colors.equineSnow,
  textOperationHoursColor: colors.equineSnow,
  textPrimaryLinksColor: colors.equineSnow,
  textPrimaryLinksHoverColor: colors.equineStone,
  seperatorColor: colors.equineSnow,
  textTransform: 'uppercase',
  tertiaryLinkFontWeight: 'normal',
  copyrightFontWeight: 'normal',
  copyRightTextTransform: 'uppercase',
  teriaryTextTransform: 'uppercase',
  primaryLinkLineHeight: '24px',
  primaryLinkLineFontSize: '13px',
  primaryLinkBottomMargin: '15px',
  headingFontSize: '24px',
  displayLargerLogo: true,
  removeBusinessLogoMargin: true,
  textColorDark: colors.equineShadow,
  textColorLight: colors.equineSnow
};
export default footer;