import { colors } from '../../colors';
var footer = {
  textColor: colors.charcoalGrey,
  primaryColor: colors.dustyTeal,
  bgColor: colors.pale,
  textHoverColor: colors.stoneTeal,
  textAddressColor: colors.charcoalGrey,
  textOperationHoursColor: colors.charcoalGrey,
  textPrimaryLinksColor: colors.dustyTeal,
  textPrimaryLinksHoverColor: colors.charcoalGrey,
  seperatorColor: colors.overcast,
  textTransform: 'uppercase',
  textColorDark: colors.charcoalGrey,
  textColorLight: colors.white
};
export default footer;