import { colors } from '../../colors';
var gallery = {
  bgColor: colors.white,
  galleryBackgroundImageStriped: colors.stripedGreen,
  gallerybackgroundSize: '82.82px 309.10px',
  textBg: colors.white,
  textColor: colors.graphite,
  modalBgColor: colors.white,
  //TODO: Replace with Striped BG ?
  closeIconColor: colors.graphite,
  modalHeadingColor: colors.graphite,
  modalCaptionColor: colors.graphite,
  modalParaColor: colors.graphite,
  textColorDark: colors.graphite,
  textColorLight: colors.white
};
export default gallery;