import { colors } from '../../colors';
var staff = {
  bgPrimaryColor: colors.alabaster,
  bgSecondaryColor: colors.white,
  bgColorBio: colors.slateGreen,
  breadcrumbColor: colors.white,
  bladeSubHeadingColor: colors.slateGreen,
  headingColor: colors.white,
  fillColor: colors.white,
  fillHoverColor: colors.slateGreen,
  iconColor: colors.slateGreen,
  iconHoverColor: colors.white,
  iconBorderColor: colors.white,
  iconBorderHoverColor: colors.white,
  fiveBladeLinkColor: colors.ash,
  textColorDark: colors.ash,
  textColorLight: colors.white
};
export default staff;