import { colors } from '../../colors';
var threeTile = {
  borderColor: colors.overcast,
  headingColor: colors.white,
  paraColor: colors.white,
  subHeadingColor: colors.white,
  bgColor: colors.darkTaupe,
  ctaColor: colors.metal,
  // was color.white but should change this to colors.dirtBrown 3-Tile Promotional Cards - TEST UNIVERSAL - Variation A - Dark
  textColorDark: colors.metal,
  textColorLight: colors.white,
  cardBgColorVariationC: colors.white,
  noSecondaryAnchorOnThreeTile: false // change it back to false when working gives tile the primary button
};

export default threeTile;