import { colors } from '../../colors';
var gallery = {
  bgColor: colors.white,
  textBg: colors.white,
  textColor: colors.ash,
  modalBgColor: colors.white,
  closeIconColor: colors.ash,
  modalHeadingColor: colors.ash,
  modalCaptionColor: colors.ash,
  modalParaColor: colors.ash,
  textColorDark: colors.ash,
  textColorLight: colors.white
};
export default gallery;