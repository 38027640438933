import { colors } from '../../colors';
var reviewCard = {
  borderRadius: 0,
  reviewLinkColor: colors.clearBlue,
  iconColor: colors.fadedGreen,
  reviewLinkHoverColor: colors.lightNavy,
  reviewTextColor: colors.darkNight,
  cardBgColor: colors.white,
  bgColor: colors.softBlue,
  headlineColor: colors.white,
  boxShadow: 'none',
  cardRadius: '20px',
  textColorDark: colors.darkNight,
  textColorLight: colors.white
};
export default reviewCard;