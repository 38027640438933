import { colors } from '../../colors';
var blogList = {
  borderColor: colors.overcast,
  bgColor: colors.white,
  breadcrumbColor: colors.deepOrange,
  subHeadingColor: colors.deepOrange,
  headingColor: colors.black,
  paraColor: colors.black,
  filterTextColor: colors.black
};
export default blogList;