import { colors } from '../../colors';
var reviewCard = {
  borderRadius: 0,
  reviewLinkColor: colors.petsuitesPurple,
  iconColor: colors.petsuitesPurple,
  reviewLinkHoverColor: colors.darkViolet,
  reviewTextColor: colors.graphite,
  cardBgColor: colors.white,
  bgColor: colors.white,
  headlineColor: colors.graphite,
  boxShadow: 'none',
  cardRadius: '20px',
  reviewsBackgroundImageStriped: colors.stripedBlue,
  // Aug 13 - added new, specific for PS, the .style file had to be changed for this as well
  reviewsbackgroundSize: '82.82px 309.10px',
  // Aug 13 - added new, specific for PS, the .style file had to be changed for this as well
  textColorDark: colors.graphite,
  textColorLight: colors.white
};
export default reviewCard;