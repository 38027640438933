import { colors } from '../../colors';
var service = {
  bgColor: colors.white,
  borderColor: colors.overcast,
  bgColorBlade: colors.white,
  carouselDotColor: colors.cornflowerBlue,
  linkColor: colors.copper,
  textColorDark: colors.lead,
  textColorLight: colors.white
};
export default service;