import { colors } from '../../colors';
var blogList = {
  borderColor: colors.overcast,
  bgColor: colors.equinePearl,
  breadcrumbColor: colors.equineStone,
  subHeadingColor: colors.equineStone,
  headingColor: colors.equineIron,
  paraColor: colors.equineIron,
  filterTextColor: colors.equineIron
};
export default blogList;