import { colors } from '../../colors';
var form = {
  bgColor: colors.equineSnow,
  labelColor: colors.equineStone,
  errorColor: colors.warningRed,
  borderColor: colors.equineIron,
  linkColor: colors.equineStone,
  documentFormBgColor: colors.equineSnow,
  textColorDark: colors.equineIron,
  textColorLight: colors.equineSnow
};
export default form;