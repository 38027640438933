import { colors } from '../../colors';
import Next from '../../../../static/images/icons/Next';
import Prev from '../../../../static/images/icons/Prev';
var carousel = {
  arrowBgColor: colors.equineShadow,
  arrowHoverBgColor: colors.equineIron,
  arrowForeColor: colors.equineSnow,
  arrowPrevMarginRightVarA: '4px',
  arrowNextMarginLeftVarA: '4px',
  highlightColor: colors.equineIron,
  paginationInactiveColor: colors.equineSnow,
  paginationActiveColor: colors.equineShadow,
  paginationBorderColor: colors.equineShadow,
  heroPaginationBorder: false,
  noBorderOnDesktopVariationAandB: true,
  heroPaginationInactiveColor: colors.equineSnow,
  heroPaginationInactiveColorMobile: colors.equineIron,
  heroPaginationInactiveColorVariationC: colors.equineIron,
  heroPaginationActiveColor: colors.equineIron,
  heroPaginationBorderColor: colors.equineShadow,
  fiveCardPaginationActiveColor: colors.equineSnow,
  bgColor: colors.equinePearl,
  iconNext: Next,
  iconPrev: Prev,
  arrowContainerBorderRadius: '2px',
  arrowContainerSquare: true
};
export default carousel;