import { colors } from '../../colors';
import Next from '../../../../static/images/icons/Next3';
import Prev from '../../../../static/images/icons/Prev3';
var carousel = {
  arrowBgColor: colors.brightRed,
  arrowHoverBgColor: colors.cherry,
  arrowForeColor: colors.white,
  arrowPrevMarginLeft: '4px',
  arrowNextMarginRight: '4px',
  highlightColor: colors.black,
  paginationInactiveColor: colors.white,
  paginationActiveColor: colors.black,
  paginationBorderColor: colors.black,
  heroPaginationBorder: false,
  noBorderOnDesktopVariationAandB: true,
  heroPaginationInactiveColor: colors.white,
  heroPaginationInactiveColorMobile: colors.brightRed,
  heroPaginationInactiveColorVariationC: colors.brightRed,
  heroPaginationActiveColor: colors.brightRed,
  heroPaginationBorderColor: colors.cherry,
  bgColor: colors.white,
  iconNext: Next,
  iconPrev: Prev
};
export default carousel;