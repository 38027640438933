import { colors } from '../../colors';
var fiveCard = {
  textColor: colors.white,
  hoverColor: colors.red,
  bgColorPara: colors.black,
  bgColor: colors.white,
  cardBgColorB: colors.white,
  cardBgColorHoverB: colors.red,
  cardCTAColorB: colors.black,
  cardCTAColorHoverB: colors.white,
  textBgOpacity: '0.5',
  iconColor: colors.brightRed,
  iconColorHover: colors.white,
  textBGWidthFull: true
};
export default fiveCard;