import { css } from 'styled-components';
var SocialIconStyles = css(["height:30px;width:30px;position:relative;border-radius:100%;background-color:", ";display:inline-block;&:not(:last-of-type){margin-right:var(--sc-spacing-4,20px);}svg,img{fill:", ";vertical-align:middle;display:inline-block;height:15px;max-width:15px;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);}img{display:block;}a{width:100%;height:100%;text-align:center;position:relative;border-radius:100%;", ";&:hover{border-radius:100%;background-color:", ";", ";", ";svg{fill:", ";}}}"], function (props) {
  var _props$theme, _props$theme$socialIc, _props$theme2, _props$theme2$socialI;
  return (_props$theme = props.theme) !== null && _props$theme !== void 0 && (_props$theme$socialIc = _props$theme.socialIcon) !== null && _props$theme$socialIc !== void 0 && _props$theme$socialIc.iconColor ? "var(--sc-social-icon--color-icon, ".concat((_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : (_props$theme2$socialI = _props$theme2.socialIcon) === null || _props$theme2$socialI === void 0 ? void 0 : _props$theme2$socialI.iconColor, ")") : 'transparent';
}, function (props) {
  var _props$theme3, _props$theme3$socialI;
  return "var(--sc-social-icon--color-fill, ".concat((_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : (_props$theme3$socialI = _props$theme3.socialIcon) === null || _props$theme3$socialI === void 0 ? void 0 : _props$theme3$socialI.fillColor, ")");
}, function (props) {
  var _props$theme4, _props$theme4$socialI, _props$theme5, _props$theme5$socialI;
  return (_props$theme4 = props.theme) !== null && _props$theme4 !== void 0 && (_props$theme4$socialI = _props$theme4.socialIcon) !== null && _props$theme4$socialI !== void 0 && _props$theme4$socialI.iconBorderColor ? "\n      border-width: 1px;\n      border-style: solid;\n      border-color: var(--sc-social-icon--border-color-icon, ".concat((_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : (_props$theme5$socialI = _props$theme5.socialIcon) === null || _props$theme5$socialI === void 0 ? void 0 : _props$theme5$socialI.iconBorderColor, ");\n        ") : '';
}, function (props) {
  var _props$theme6, _props$theme6$socialI;
  return "var(--sc-social-icon--color-icon-hover, ".concat((_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : (_props$theme6$socialI = _props$theme6.socialIcon) === null || _props$theme6$socialI === void 0 ? void 0 : _props$theme6$socialI.iconHoverColor, ")");
}, function (props) {
  var _props$theme7, _props$theme7$socialI, _props$theme8, _props$theme8$socialI;
  return (_props$theme7 = props.theme) !== null && _props$theme7 !== void 0 && (_props$theme7$socialI = _props$theme7.socialIcon) !== null && _props$theme7$socialI !== void 0 && _props$theme7$socialI.iconBorderHoverColor ? "\n        border-color: var(--sc-social-icon--border-color-icon-hover, ".concat((_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : (_props$theme8$socialI = _props$theme8.socialIcon) === null || _props$theme8$socialI === void 0 ? void 0 : _props$theme8$socialI.iconBorderHoverColor, ");\n          ") : '';
}, function (props) {
  var _props$theme9, _props$theme9$socialI, _props$theme10, _props$theme10$social;
  return (_props$theme9 = props.theme) !== null && _props$theme9 !== void 0 && (_props$theme9$socialI = _props$theme9.socialIcon) !== null && _props$theme9$socialI !== void 0 && _props$theme9$socialI.opacity ? "\n        opacity: var(--sc-social-icon--opacity, ".concat((_props$theme10 = props.theme) === null || _props$theme10 === void 0 ? void 0 : (_props$theme10$social = _props$theme10.socialIcon) === null || _props$theme10$social === void 0 ? void 0 : _props$theme10$social.opacity, ");\n          ") : '';
}, function (props) {
  var _props$theme11, _props$theme11$social, _props$theme12, _props$theme12$social, _props$theme13, _props$theme13$social;
  return (_props$theme11 = props.theme) !== null && _props$theme11 !== void 0 && (_props$theme11$social = _props$theme11.socialIcon) !== null && _props$theme11$social !== void 0 && _props$theme11$social.fillHoverColor ? "var(--sc-social-icon--color-fill-hover, ".concat((_props$theme12 = props.theme) === null || _props$theme12 === void 0 ? void 0 : (_props$theme12$social = _props$theme12.socialIcon) === null || _props$theme12$social === void 0 ? void 0 : _props$theme12$social.fillHoverColor, ")") : "var(--sc-social-icon--color-icon-hover, ".concat((_props$theme13 = props.theme) === null || _props$theme13 === void 0 ? void 0 : (_props$theme13$social = _props$theme13.socialIcon) === null || _props$theme13$social === void 0 ? void 0 : _props$theme13$social.iconHoverColor, ")");
});
export default SocialIconStyles;