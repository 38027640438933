import { colors } from '../../colors';
var footer = {
  textColor: colors.black,
  primaryColor: colors.black,
  bgColor: colors.frost,
  textHoverColor: colors.white,
  textAddressColor: colors.black,
  textOperationHoursColor: colors.black,
  textPrimaryLinksColor: colors.black,
  textPrimaryLinksHoverColor: colors.deepOrange,
  seperatorColor: colors.overcast,
  textTransform: 'uppercase',
  headerTransform: 'uppercase',
  textColorDark: colors.black,
  textColorLight: colors.white
};
export default footer;