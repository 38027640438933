import { colors } from '../../colors';
var service = {
  bgColor: colors.eggShell,
  borderColor: colors.overcast,
  bgColorBlade: colors.eggShell,
  carouselDotColor: colors.darkSage,
  linkColor: colors.brickOrange,
  textColorDark: colors.smoke,
  textColorLight: colors.white
};
export default service;