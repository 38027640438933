/**
 * @file Manages styles for Paragraph atom.
 * @author kkumari
 */
import { css } from 'styled-components';
export default css(["font-weight:normal;padding:0;", " &.left{margin-right:var(--sc-spacing-4,15px);width:68px;white-space:nowrap;}&.message{letter-spacing:1.4px;color:var(--sc-para--message-color,", ");margin:var(--sc-spacing-3,10px) auto var(--sc-spacing-2,5px) 0;padding:0;}"], function (props) {
  return props.styleType === 'skipButton' ? "\n    position:absolute;\n    margin: unset;\n  " : '';
}, function (props) {
  var _props$theme, _props$theme$para;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$para = _props$theme.para) === null || _props$theme$para === void 0 ? void 0 : _props$theme$para.messageColor;
});