import { colors } from '../../colors';
var staff = {
  bgPrimaryColor: colors.lightGrey,
  bgSecondaryColor: colors.white,
  bgColorBio: colors.white,
  breadcrumbColor: colors.deepOrange,
  fillColor: colors.white,
  fillHoverColor: colors.white,
  iconColor: colors.black,
  iconHoverColor: colors.deepOrange,
  iconBorderColor: colors.black,
  iconBorderHoverColor: colors.deepOrange,
  bladeSubHeadingColor: colors.deepOrange,
  fiveBladeLinkColor: colors.deepOrange,
  textColorDark: colors.black,
  textColorLight: colors.white
};
export default staff;