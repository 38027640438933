import { colors } from '../../colors';
var button = {
  //Hero button
  linkColorHero: colors.white,
  linkColorHeroHover: colors.white,
  backgroundColorHero: colors.marine,
  backgroundColorHoverHero: colors.navy,
  borderRadiusHero: '0',
  linkLineHeightHero: '30px',
  heroButtonPadding: '20px 35px',
  //primary button
  linkColorPrimary: colors.white,
  linkColorPrimaryHover: colors.white,
  backgroundColorPrimary: colors.marine,
  backgroundColorHoverPrimary: colors.navy,
  borderRadiusPrimary: '0',
  //secondary button
  linkColorSecondary: colors.white,
  backgroundColorSecondary: 'transparent',
  backgroundColorHoverSecondary: colors.navy,
  borderRadiusSecondary: '0',
  linkColorSecondaryHover: colors.white,
  borderColorSecondary: colors.white,
  borderColorSecondaryHover: 'transparent',
  //secondary 'Dark' button
  hasAlternateDarkSecondaryAnchor: true,
  linkColorAltSecondary: colors.dirtBrown,
  backgroundColorAltSecondary: 'transparent',
  backgroundColorHoverAltSecondary: colors.dirtBrown,
  linkColorAltSecondaryHover: colors.white,
  borderColorAltSecondary: colors.dirtBrown,
  borderColorAltSecondaryHover: colors.dirtBrown,
  // props below alter the styling of the primary button in the footer
  // for this theme, the bg color of the primary is the same as
  // the bg of the footer. This only happens if the bg for the footer
  // is NOT replaced by the bg override.
  // this is a temporary solution.
  hasAlternatePrimaryAnchorFooter: true,
  backgroundColorLightPrimary: colors.navy,
  backgroundColorHoverLightPrimary: 'rgba(255, 255, 255, 0.1)'
};
export default button;