import { colors } from '../../colors';
import fonts from '../../fonts';
var header = {
  primaryColor: colors.graphite,
  hoverColor: colors.darkViolet,
  borderColor: colors.overcast,
  bgColor: colors.white,
  barColor: colors.graphite,
  navContactLinkHoverTextDecoration: "underline",
  navUtilityLinkColor: colors.graphite,
  navUtilityLinkHoverColor: colors.graphite,
  navUtilityLinkTextDecoration: "none",
  navUtilityLinkHoverTextDecoration: "underline",
  topNavLineHeight: '17px',
  topNavLeftUtilities: colors.darkViolet,
  topNavRightUtilities: fonts.FONT_NUNTINO_REGULAR
};
export default header;