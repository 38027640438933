/**
 * @file Manages styles for RichText atom.
 * @author harmeet
 */
import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
/* stylelint-disable */
var styles = css(["", ";.rich-text-blob &{padding-left:var(--sc-spacing-4,20px);padding-right:var(--sc-spacing-4,20px);li *:last-child{margin-bottom:0;}li > p{margin-bottom:calc(1.45rem / 2);}li > ul{margin-left:1.45rem;}p{margin:0 0 1.45rem 0;padding:0;}h2{margin:0 0 1.45rem 0;padding:0;}@media (min-width:", "){padding-left:var(--sc-spacing-16,114px);padding-right:var(--sc-spacing-16,114px);}@media (min-width:", "){padding-left:0;padding-right:0;}}"], function (props) {
  return props.inheritedStyles ? props.inheritedStyles : '';
}, breakPoints.mobile, breakPoints.desktop);
/* stylelint-enable */
export default styles;