import { colors } from '../../colors';
var button = {
  //Hero button
  linkColorHero: colors.white,
  linkColorHeroHover: colors.white,
  backgroundColorHero: colors.duskBlue,
  backgroundColorHoverHero: colors.deepWater,
  borderRadiusHero: '0',
  linkLineHeightHero: '30px',
  heroButtonPadding: '20px 35px',
  //primary button
  linkColorPrimary: colors.white,
  linkColorPrimaryHover: colors.white,
  backgroundColorPrimary: colors.duskBlue,
  backgroundColorHoverPrimary: colors.deepWater,
  borderRadiusPrimary: '0',
  //secondary button
  linkColorSecondary: colors.white,
  backgroundColorSecondary: colors.rust,
  backgroundColorHoverSecondary: colors.deepRed,
  borderRadiusSecondary: '0',
  linkColorSecondaryHover: colors.white,
  borderColorSecondary: colors.rust,
  borderColorSecondaryHover: colors.deepRed
};
export default button;