import { colors } from '../../colors';
var staff = {
  bgPrimaryColor: colors.white,
  bgSecondaryColor: colors.softPink,
  bgColorBio: colors.paleSkyBlue,
  breadcrumbColor: colors.pinkishRed,
  fillColor: colors.brightGreen,
  fillHoverColor: colors.white,
  iconColor: colors.white,
  iconHoverColor: colors.brightGreen,
  iconBorderColor: colors.brightGreen,
  iconBorderHoverColor: colors.brightGreen,
  bladeSubHeadingColor: colors.pinkishRed,
  fiveBladeLinkColor: colors.brightGreen,
  textColorDark: colors.black,
  textColorLight: colors.white
};
export default staff;