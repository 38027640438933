import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function Facebook(props) {
  return __jsx("svg", _extends({
    viewBox: "0 0 7.4 16"
  }, props), __jsx("path", {
    d: "M1.6 16h3.3V8h2.2l.3-2.7H4.9V3.8c0-.7.1-1.1 1.1-1.1h1.4V0H5.2C2.6 0 1.6 1.3 1.6 3.6v1.6H0V8h1.6v8z"
  }));
}
export default Facebook;