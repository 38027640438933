import { colors } from '../../colors';
var fiveCard = {
  textColor: colors.ash,
  hoverColor: colors.slateGreen,
  bgColorPara: colors.white,
  bgColor: colors.slateGreen,
  cardBgColorB: colors.white,
  cardBgColorHoverB: colors.slateGreen,
  cardCTAColorB: colors.slateGreen,
  cardCTAColorHoverB: colors.white,
  iconColor: colors.slateGreen,
  iconColorHover: colors.white
};
export default fiveCard;