import { colors } from '../../colors';
var service = {
  bgColor: colors.equineSnow,
  borderColor: colors.equineSnow,
  bgColorBlade: colors.equineSnow,
  alternateBgColorBlade: colors.equinePearl,
  carouselDotColor: colors.equineStone,
  linkColor: colors.equineShadow,
  linkHoverColor: colors.equineStone,
  linkUppercase: true,
  linkLeftAligned: true,
  textColorDark: colors.equineIron,
  textColorLight: colors.equineSnow,
  alternateBgColor: false,
  containerBottomMargin: '60px',
  serviceTileBottomMargin: '60px',
  serviceTileMobileBottomMargin: '60px',
  addSidePaddingToImageOnMobile: true,
  paragraphLineHeight: '28px',
  addSidePaddingToServiceDetailImageOnMobile: true,
  serviceGalleryMarginBottom: '0',
  serviceNamePadding: '40px 20px 20px',
  serviceNameMobilePadding: '40px 114px 20px',
  serviceOverviewPadding: '0 20px',
  serviceOverviewMobilePadding: '0',
  serviceDetailsPadding: '24px 0 60px'
};
export default service;