import { colors } from '../../colors';
var blogList = {
  borderColor: colors.nvaLightGrey,
  bgColor: colors.white,
  breadcrumbColor: colors.nvaTeal,
  subHeadingColor: colors.nvaTeal,
  headingColor: colors.nvaBlack,
  paraColor: colors.nvaBlack,
  filterTextColor: colors.nvaBlack
};
export default blogList;