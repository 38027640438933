import { colors } from '../../colors';
var gallery = {
  bgColor: colors.eggShell,
  textBg: colors.darkSage,
  textColor: colors.white,
  modalBgColor: colors.lightTan,
  closeIconColor: colors.smoke,
  modalHeadingColor: colors.smoke,
  modalCaptionColor: colors.smoke,
  modalParaColor: colors.smoke,
  textColorDark: colors.smoke,
  textColorLight: colors.white
};
export default gallery;