import { colors } from '../../colors';
var footer = {
  textColor: colors.lead,
  primaryColor: colors.cornflowerBlue,
  bgColor: colors.white,
  textHoverColor: colors.lead,
  textAddressColor: colors.lead,
  textOperationHoursColor: colors.lead,
  textPrimaryLinksColor: colors.cornflowerBlue,
  textPrimaryLinksHoverColor: colors.cornflowerBlue,
  seperatorColor: colors.overcast,
  textTransform: 'uppercase',
  primaryLinksFontWeight: 'normal',
  tertiaryLinkLetterSpacing: '0.13px',
  textColorDark: colors.lead,
  textColorLight: colors.white
};
export default footer;