import { colors } from '../../colors';
var blogList = {
  borderColor: colors.overcast,
  bgColor: colors.alabaster,
  breadcrumbColor: colors.slateGreen,
  subHeadingColor: colors.slateGreen,
  headingColor: colors.ash,
  paraColor: colors.ash,
  filterTextColor: colors.ash
};
export default blogList;