import { css } from 'styled-components';
var textures = [{
  key: 'dogBlue',
  label: 'Dog Blue',
  style: css(["background-color:rgb(97,155,233);background-image:url('/static/images/textures/PlayfulV3/dogBlue.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catBlue',
  label: 'Cat Blue',
  style: css(["background-color:rgb(97,155,233);background-image:url('/static/images/textures/PlayfulV3/catBlue.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesBlue',
  label: 'Bones Blue',
  style: css(["background-color:rgb(97,155,233);background-image:url('/static/images/textures/PlayfulV3/bonesBlue.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricBlue',
  label: 'Geometric Blue',
  style: css(["background-color:rgb(97,155,233);background-image:url('/static/images/textures/PlayfulV3/geometricBlue.svg');background-repeat:repeat;background-size:846px 594px;"])
}, {
  key: 'dogGreen',
  label: 'Dog Green',
  style: css(["background-color:rgb(123,185,115);background-image:url('/static/images/textures/PlayfulV3/dogGreen.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catGreen',
  label: 'Cat Green',
  style: css(["background-color:rgb(123,185,115);background-image:url('/static/images/textures/PlayfulV3/catGreen.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesGreen',
  label: 'Bones Green',
  style: css(["background-color:rgb(123,185,115);background-image:url('/static/images/textures/PlayfulV3/bonesGreen.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricGreen',
  label: 'Geometric Green',
  style: css(["background-color:rgb(123,185,115);background-image:url('/static/images/textures/PlayfulV3/geometricGreen.svg');background-repeat:repeat;background-size:846px 594px;"])
}, {
  key: 'dogYellow',
  label: 'Dog Yellow',
  style: css(["background-color:rgb(252,200,117);background-image:url('/static/images/textures/PlayfulV3/dogYellow.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catYellow',
  label: 'Cat Yellow',
  style: css(["background-color:rgb(252,200,117);background-image:url('/static/images/textures/PlayfulV3/catYellow.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesYellow',
  label: 'Bones Yellow',
  style: css(["background-color:rgb(252,200,117);background-image:url('/static/images/textures/PlayfulV3/bonesYellow.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricYellow',
  label: 'Geometric Yellow',
  style: css(["background-color:rgb(252,200,117);background-image:url('/static/images/textures/PlayfulV3/geometricYellow.svg');background-repeat:repeat;background-size:846px 594px;"])
}];
export default textures;