import { colors } from '../../colors';
var gallery = {
  bgColor: colors.marine,
  textBg: colors.white,
  textColor: colors.dirtBrown,
  modalBgColor: colors.powder,
  closeIconColor: colors.metal,
  modalHeadingColor: colors.metal,
  modalCaptionColor: colors.dirtBrown,
  modalParaColor: colors.metal,
  textColorDark: colors.metal,
  textColorLight: colors.white
};
export default gallery;