import { colors } from '../../colors';
var footer = {
  textColor: colors.slateGrey,
  primaryColor: colors.slateGrey,
  bgColor: colors.paleGrey,
  textHoverColor: colors.slateGrey,
  textAddressColor: colors.slateGrey,
  textOperationHoursColor: colors.slateGrey,
  textPrimaryLinksColor: colors.neonPurple,
  textPrimaryLinksHoverColor: colors.neonPurple,
  seperatorColor: colors.overcast,
  primaryLinksFontWeight: 'normal',
  tertiaryLinkLetterSpacing: '0.13px',
  textColorDark: colors.slateGrey,
  textColorLight: colors.white
};
export default footer;