import { colors } from '../../colors';
import Next from '../../../../static/images/icons/Next';
import Prev from '../../../../static/images/icons/Prev';
var carousel = {
  arrowBgColor: colors.marine,
  arrowHoverBgColor: colors.navy,
  arrowForeColor: colors.white,
  arrowPrevMarginRightVarA: '4px',
  arrowNextMarginLeftVarA: '4px',
  highlightColor: colors.metal,
  paginationInactiveColor: colors.white,
  paginationActiveColor: colors.marine,
  paginationBorderColor: colors.marine,
  heroPaginationBorder: false,
  noBorderOnDesktopVariationAandB: true,
  heroPaginationInactiveColor: colors.white,
  heroPaginationInactiveColorMobile: colors.marine,
  heroPaginationInactiveColorVariationC: colors.marine,
  heroPaginationActiveColor: colors.marine,
  heroPaginationBorderColor: colors.navy,
  bgColor: colors.powder,
  iconNext: Next,
  iconPrev: Prev
};
export default carousel;