import { colors } from '../../colors';
var form = {
  bgColor: colors.alabaster,
  labelColor: colors.slateGreen,
  errorColor: colors.warningRed,
  borderColor: colors.ash,
  linkColor: colors.slateGreen,
  documentFormBgColor: colors.alabaster,
  textColorDark: colors.ash,
  textColorLight: colors.white
};
export default form;