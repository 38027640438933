// The list of colors in a theme have human-friendly names based on their value
// There should never be names based on how the color is applied to elements
var palette = require('./colors.json');

// Convert the list of colors to named keys
var colors = palette.reduce(function (reducer, color) {
  reducer[color.key] = color.value;
  return reducer;
}, {});
colors = Object.assign(colors);
export { colors, palette };