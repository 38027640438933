import { colors } from '../../colors';
var gallery = {
  bgColor: colors.alabaster,
  textBg: colors.white,
  textColor: colors.slateGreen,
  modalBgColor: colors.ash,
  closeIconColor: colors.white,
  modalHeadingColor: colors.white,
  modalCaptionColor: colors.white,
  modalParaColor: colors.white,
  textColorDark: colors.ash,
  textColorLight: colors.white
};
export default gallery;