import { colors } from '../../colors';
var gallery = {
  bgColor: colors.equineSnow,
  textBg: colors.equineSnow,
  textColor: colors.equineStone,
  modalBgColor: colors.equineIron,
  closeIconColor: colors.equineSnow,
  modalHeadingColor: colors.equineSnow,
  modalCaptionColor: colors.equineSnow,
  modalParaColor: colors.equineSnow,
  textColorDark: colors.equineIron,
  textColorLight: colors.equineSnow,
  largerPrevNextButtonsOnModal: true
};
export default gallery;