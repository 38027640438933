import { colors } from '../../colors';
var header = {
  primaryColor: colors.black,
  hoverColor: colors.red,
  borderColor: colors.overcast,
  bgColor: colors.white,
  barColor: colors.black,
  navContactLinkHoverTextDecoration: "underline",
  navUtilityLinkColor: colors.black,
  navUtilityLinkHoverColor: colors.jaguar,
  navUtilityLinkTextDecoration: "none",
  navUtilityLinkHoverTextDecoration: "underline",
  topNavLineHeight: '16px'
};
export default header;