import { colors } from '../../colors';
var form = {
  bgColor: colors.eggShell,
  labelColor: colors.smoke,
  errorColor: colors.warningRed,
  borderColor: colors.smoke,
  linkColor: colors.brickOrange,
  documentFormBgColor: colors.eggShell,
  textColorDark: colors.smoke,
  textColorLight: colors.white
};
export default form;