import { colors } from '../../colors';
var button = {
  //Hero button
  linkColorHero: colors.white,
  linkColorHeroHover: colors.white,
  backgroundColorHero: colors.petsuitesPurple,
  backgroundColorHoverHero: colors.darkViolet,
  borderRadiusHero: '3px',
  linkLineHeightHero: '30px',
  heroButtonPadding: '20px 35px',
  //primary button
  linkColorPrimary: colors.white,
  linkColorPrimaryHover: colors.white,
  backgroundColorPrimary: colors.petsuitesPurple,
  backgroundColorHoverPrimary: colors.darkViolet,
  borderRadiusPrimary: '3px',
  //secondary button
  linkColorSecondary: colors.white,
  linkColorSecondaryHover: colors.white,
  backgroundColorSecondary: colors.petsuitesPurple,
  backgroundColorHoverSecondary: colors.darkViolet,
  borderRadiusSecondary: '3px',
  borderColorSecondary: 'transparent',
  borderColorSecondaryHover: 'transparent'
};
export default button;