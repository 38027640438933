import { colors } from '../../colors';
var staff = {
  bgPrimaryColor: colors.nvaBrightBlue,
  bgSecondaryColor: colors.white,
  bgColorBio: colors.nvaLightGrey,
  breadcrumbColor: colors.nvaBlack,
  headingColor: colors.nvaBlack,
  fiveBladeLinkColor: colors.nvaBlack,
  textColorDark: colors.smoke,
  textColorLight: colors.white
};
export default staff;