import { colors } from '../../colors';
var staff = {
  bgPrimaryColor: colors.whiteish,
  bgSecondaryColor: colors.whiteish,
  bgColorBio: colors.white,
  breadcrumbColor: colors.onyx,
  fillColor: colors.white,
  fillHoverColor: colors.white,
  iconColor: colors.onyx,
  iconHoverColor: colors.azure,
  iconBorderColor: colors.onyx,
  iconBorderHoverColor: colors.azure,
  bladeSubHeadingColor: colors.onyx,
  fiveBladeLinkColor: colors.azure,
  textColorDark: colors.onyx,
  textColorLight: colors.white
};
export default staff;