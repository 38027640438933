import { colors } from '../../colors';
var threeTile = {
  borderColor: colors.overcast,
  headingColor: colors.lead,
  paraColor: colors.lead,
  subHeadingColor: colors.copper,
  bgColor: colors.lightPink,
  ctaColor: colors.cornflowerBlue,
  textColorDark: colors.lead,
  textColorLight: colors.white,
  cardBgColorVariationC: colors.white
};
export default threeTile;