import { colors } from '../../colors';
var staff = {
  bgPrimaryColor: colors.palePurple,
  bgSecondaryColor: colors.white,
  bgColorBio: colors.white,
  breadcrumbColor: colors.neonPurple,
  fillColor: colors.neonPurple,
  fillHoverColor: colors.white,
  iconColor: colors.white,
  iconHoverColor: colors.neonPurple,
  iconBorderColor: colors.neonPurple,
  iconBorderHoverColor: colors.neonPurple,
  bladeSubHeadingColor: colors.slateGrey,
  fiveBladeLinkColor: colors.neonPurple,
  textColorDark: colors.slateGrey,
  textColorLight: colors.white
};
export default staff;