import { colors } from '../../colors';
var service = {
  bgColor: colors.white,
  borderColor: colors.overcast,
  bgColorBlade: colors.white,
  carouselDotColor: colors.coral,
  linkColor: colors.tomatoRed,
  textColorDark: colors.ash,
  textColorLight: colors.white
};
export default service;