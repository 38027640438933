import { colors } from '../../colors';
var fiveCard = {
  textColor: colors.graphite,
  hoverColor: colors.petsuitesPurple,
  bgColorPara: colors.white,
  bgColor: colors.white,
  cardBgColorB: colors.petsuitesPurple,
  cardBgColorHoverB: colors.darkViolet,
  cardCTAColorB: colors.white,
  cardCTAColorHoverB: colors.petsuitesPurple,
  cardRadius: '20px',
  textBgOpacity: '0.5',
  iconColor: colors.white,
  iconColorHover: colors.petsuitesPurple,
  textBGWidthFull: true
};
export default fiveCard;