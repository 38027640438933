export var createUrlSearchParams = function createUrlSearchParams() {
  var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var queryParams = [];
  var keys = Object.keys(query);
  for (var i = 0, l = keys.length; i < l; i += 1) {
    queryParams.push("".concat(keys[i], "=").concat(query[keys[i]]));
  }
  return queryParams.join('&');
};
export var buildUrl = function buildUrl(options) {
  if (typeof options === 'object') {
    var pathname = options.pathname,
      query = options.query;
    var url = pathname;
    if (typeof query === 'object') {
      url += "?".concat(createUrlSearchParams(query));
    }
    return url;
  }
  return options;
};