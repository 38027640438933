import { colors } from '../../colors';
var fiveCard = {
  textColor: colors.white,
  hoverColor: colors.powder,
  bgColorPara: colors.dirtBrown,
  bgColor: colors.powder,
  cardBgColorB: colors.marine,
  cardBgColorHoverB: colors.white,
  cardCTAColorB: colors.white,
  cardCTAColorHoverB: colors.marine,
  textBgOpacity: '0.5',
  iconColor: colors.white,
  iconColorHover: colors.marine
};
export default fiveCard;