import { colors } from '../../colors';
var gallery = {
  bgColor: colors.sandy,
  textBg: colors.white,
  textColor: colors.clearBlue,
  modalBgColor: colors.fadedGreen,
  closeIconColor: colors.white,
  modalHeadingColor: colors.white,
  modalCaptionColor: colors.white,
  modalParaColor: colors.white,
  textColorDark: colors.darkNight,
  textColorLight: colors.white
};
export default gallery;