import { colors } from '../../colors';
var blogList = {
  borderColor: colors.overcast,
  bgColor: colors.white,
  breadcrumbColor: colors.petsuitesPurple,
  subHeadingColor: colors.graphite,
  headingColor: colors.graphite,
  paraColor: colors.graphite,
  blogTileImageRadius: '20px',
  filterTextColor: colors.graphite
};
export default blogList;