import { colors } from '../../colors';
var reviewCard = {
  borderRadius: 0,
  reviewLinkColor: colors.dustyTeal,
  iconColor: colors.dustyTeal,
  reviewLinkHoverColor: colors.stoneTeal,
  reviewTextColor: colors.charcoalGrey,
  cardBgColor: colors.white,
  bgColor: colors.pale,
  headlineColor: colors.charcoalGrey,
  boxShadow: 'none',
  textColorDark: colors.charcoalGrey,
  textColorLight: colors.white
};
export default reviewCard;