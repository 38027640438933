import { colors } from '../../colors';
var fiveCard = {
  textColor: colors.charcoalGrey,
  hoverColor: colors.dustyTeal,
  bgColorPara: colors.white,
  bgColor: colors.white,
  cardBgColorB: colors.white,
  cardBgColorHoverB: colors.dustyTeal,
  cardCTAColorB: colors.charcoalGrey,
  cardCTAColorHoverB: colors.white,
  iconColor: colors.dustyTeal,
  iconColorHover: colors.white
};
export default fiveCard;