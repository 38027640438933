import { colors } from '../../colors';
var form = {
  bgColor: colors.white,
  labelColor: colors.nvaBlack,
  errorColor: colors.warningRed,
  borderColor: colors.nvaLightBrown,
  linkColor: colors.dustyTeal,
  documentFormBgColor: colors.nvaTeal,
  footerErrorColor: colors.lightred,
  textColorDark: colors.ash,
  textColorLight: colors.white
};
export default form;