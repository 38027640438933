import { colors } from '../../colors';
var card = {
  textColorDark: colors.equineShadow,
  textColorLight: colors.equineSnow,
  bgColor: colors.equinePearl,
  bgColorHero: colors.equinePearl,
  subtleShadow: true,
  heroRichTextSize: '22px',
  heroTabletPadding: '30px 114px 0',
  heroMobilePadding: '30px 20px 0'
};
export default card;