import { colors } from '../../colors';
var form = {
  bgColor: colors.white,
  labelColor: colors.deepOrange,
  errorColor: colors.warningRed,
  borderColor: colors.black,
  linkColor: colors.deepOrange,
  documentFormBgColor: colors.white,
  documentFormH4Color: colors.black,
  textColorDark: colors.black,
  textColorLight: colors.white
};
export default form;