import { colors } from '../../colors';
var footer = {
  textColor: colors.ash,
  primaryColor: colors.coral,
  bgColor: colors.cream,
  textHoverColor: colors.ash,
  textAddressColor: colors.ash,
  textOperationHoursColor: colors.ash,
  textPrimaryLinksColor: colors.ash,
  textPrimaryLinksHoverColor: colors.tomatoRed,
  seperatorColor: colors.overcast,
  textTransform: 'uppercase',
  primaryLinksFontWeight: 'normal',
  copyRightTextTransform: 'uppercase',
  teriaryTextTransform: 'uppercase',
  textColorDark: colors.ash,
  textColorLight: colors.white
};
export default footer;