import { colors } from '../../colors';
var reviewCard = {
  borderRadius: 0,
  reviewLinkColor: colors.slateGreen,
  iconColor: colors.pine,
  reviewLinkHoverColor: colors.militaryGreen,
  reviewTextColor: colors.ash,
  cardBgColor: colors.white,
  bgColor: colors.alabaster,
  headlineColor: colors.ash,
  boxShadow: 'none',
  textColorDark: colors.ash,
  textColorLight: colors.white
};
export default reviewCard;