import { colors } from '../../colors';
var reviewCard = {
  borderRadius: 0,
  reviewLinkColor: colors.azure,
  reviewLinkHoverColor: colors.deepSeaBlue,
  iconColor: colors.azure,
  reviewTextColor: colors.onxy,
  cardBgColor: colors.white,
  bgColor: colors.whiteish,
  headlineColor: colors.onxy,
  boxShadow: 'none',
  textColorDark: colors.onyx,
  textColorLight: colors.white
};
export default reviewCard;