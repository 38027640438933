import { colors } from '../../colors';
var reviewCard = {
  borderRadius: 0,
  reviewLinkColor: colors.tomatoRed,
  iconColor: colors.coral,
  reviewLinkHoverColor: colors.rustRed,
  reviewTextColor: colors.ash,
  cardBgColor: colors.white,
  bgColor: colors.cream,
  headlineColor: colors.ash,
  boxShadow: 'none',
  textColorDark: colors.ash,
  textColorLight: colors.white
};
export default reviewCard;