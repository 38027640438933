import { colors } from '../../colors';
var header = {
  primaryColor: colors.equineIron,
  hoverColor: colors.equineStone,
  borderColor: colors.overcast,
  bgColor: colors.equineSnow,
  barColor: colors.equineStone,
  navContactLinkHoverTextDecoration: "underline",
  navUtilityLinkColor: colors.equineStone,
  navUtilityLinkHoverColor: colors.equineStone,
  navUtilityLinkTextDecoration: "none",
  navUtilityLinkHoverTextDecoration: "underline",
  topNavLineHeight: '13px',
  displayLargerLogo: true,
  useAlternateBottomNavLayout: true,
  displaySocialIconsInTopNav: true,
  reduceNavContactsWidth: true,
  removeContactsPipe: true
};
export default header;