import { colors } from '../../colors';
var header = {
  primaryColor: colors.slateGrey,
  hoverColor: colors.amethyst,
  borderColor: colors.overcast,
  bgColor: colors.white,
  barColor: colors.slateGrey,
  navContactLinkHoverTextDecoration: "underline",
  navUtilityLinkColor: colors.slateGrey,
  navUtilityLinkHoverColor: colors.slateGrey,
  navUtilityLinkTextDecoration: "none",
  navUtilityLinkHoverTextDecoration: "underline",
  topNavLineHeight: '17px'
};
export default header;