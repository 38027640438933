import { colors } from '../../colors';
var threeTile = {
  borderColor: colors.overcast,
  headingColor: colors.smoke,
  paraColor: colors.smoke,
  subHeadingColor: colors.smoke,
  bgColor: colors.salmon,
  ctaColor: colors.darkSage,
  textColorDark: colors.smoke,
  textColorLight: colors.white,
  cardBgColorVariationC: colors.white
};
export default threeTile;