import { colors } from '../../colors';
var service = {
  bgColor: colors.whiteish,
  borderColor: colors.overcast,
  bgColorBlade: colors.whiteish,
  carouselDotColor: colors.azure,
  linkColor: colors.clearBlue,
  textColorDark: colors.onyx,
  textColorLight: colors.white
};
export default service;