import { colors } from '../../colors';
var threeTile = {
  borderColor: colors.nvaTeal,
  headingColor: colors.nvaBlack,
  paraColor: colors.nvaBlack,
  subHeadingColor: colors.nvaBlack,
  bgColor: colors.nvaBrightBlue,
  ctaColor: colors.nvaTeal,
  tileWrapperPaddingBottom: '70px',
  textColorDark: colors.charcoalGrey,
  textColorLight: colors.white,
  cardBgColorVariationC: colors.white,
  noSecondaryAnchorOnThreeTile: true
};
export default threeTile;