import { colors } from '../../colors';
var gallery = {
  bgColor: colors.lightGrey,
  textBg: colors.white,
  textColor: colors.deepOrange,
  modalBgColor: colors.frost,
  closeIconColor: colors.black,
  modalHeadingColor: colors.black,
  modalCaptionColor: colors.deepOrange,
  modalParaColor: colors.black,
  textColorDark: colors.black,
  textColorLight: colors.white
};
export default gallery;