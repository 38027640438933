/**
 * @file Manages the fonts values for the theme Modern V1.
 * @author kkumari
 */export default {
  FONT_FJALLAONE_REGULAR: 'FjallaOneRegular, sans-serif',
  FONT_OPENSANS_SEMIBOLD: 'OpenSansSemiBold, sans-serif',
  FONT_OPENSANS_REGULAR: 'OpenSansRegular, sans-serif',
  FONT_SIZE_BASE: '16px',
  FONT_SIZE_SMALL: '10px',
  FONT_SIZE_DEFAULT: '14px',
  FONT_LETTER_SPACING_DEFAULT: '1.4px',
  FONT_LINE_HEIGHT_DEFAULT: '18px',
  FONT_ICONS: 'Arial, sans-serif',
  FONT_WEIGHT_BOLD: 'bold',
  FONT_WEIGHT_NORMAL: 'normal',
  FONT_WEIGHT_500: '500',
  //Mobile values
  //H1
  FONT_SIZE_H1: '44px',
  LINE_HEIGHT_H1: '54px',
  LETTER_SPACING_H1: '2.2px',
  //H2
  FONT_SIZE_H2: '26px',
  LINE_HEIGHT_H2: '36px',
  LETTER_SPACING_H2: '1.56px',
  //H3
  FONT_SIZE_H3: '20px',
  LINE_HEIGHT_H3: '42px',
  LETTER_SPACING_H3: '1.2px',
  //H4
  FONT_SIZE_H4: '16px',
  LINE_HEIGHT_H4: '16px',
  LETTER_SPACING_H4: '0.96px',
  //H5
  FONT_SIZE_H5: '11px',
  LINE_HEIGHT_H5: '15px',
  LETTER_SPACING_H5: '0.11px',
  //P
  FONT_SIZE_P: '14px',
  LINE_HEIGHT_P: '24px',
  LETTER_SPACING_P: 'normal',
  DESKTOP: {
    //Desktop values
    //H1
    FONT_SIZE_H1: '80px',
    LINE_HEIGHT_H1: '90px',
    LETTER_SPACING_H1: '5px',
    //H2
    FONT_SIZE_H2: '36px',
    LINE_HEIGHT_H2: '54px',
    LETTER_SPACING_H2: '2.16px',
    //H3
    FONT_SIZE_H3: '30px',
    LINE_HEIGHT_H3: '40px',
    LETTER_SPACING_H3: '1.8px',
    //H4
    FONT_SIZE_H4: '20px',
    LINE_HEIGHT_H4: '25px',
    LETTER_SPACING_H4: '2px',
    //H5
    FONT_SIZE_H5: '12px',
    LINE_HEIGHT_H5: '17px',
    LETTER_SPACING_H5: '0.12px',
    //P
    FONT_SIZE_P: '15px',
    LINE_HEIGHT_P: '28px',
    LETTER_SPACING_P: 'normal'
  },
  FONT_FACES: ['FjallaOneRegular', 'OpenSansSemiBold', 'OpenSansRegular']
};