import { colors } from '../../colors';
var footer = {
  textColor: colors.darkNight,
  primaryColor: colors.orangish,
  bgColor: colors.ivory,
  textHoverColor: colors.white,
  textAddressColor: colors.darkNight,
  textOperationHoursColor: colors.darkNight,
  textPrimaryLinksColor: colors.orangish,
  textPrimaryLinksHoverColor: colors.burntOrange,
  seperatorColor: colors.overcast,
  primaryLinksFontWeight: 'normal',
  tertiaryLinkLetterSpacing: '0.26px',
  textColorDark: colors.darkNight,
  textColorLight: colors.white
};
export default footer;