import { colors } from '../../colors';
var form = {
  bgColor: colors.white,
  labelColor: colors.slateGrey,
  errorColor: colors.warningRed,
  borderColor: colors.slateGrey,
  linkColor: colors.neonPurple,
  documentFormBgColor: colors.white,
  textColorDark: colors.slateGrey,
  textColorLight: colors.white
};
export default form;