import ModernV1 from './ModernV1';
import ModernV2 from './ModernV2';
import ModernV3 from './ModernV3';
import EarthyV1 from './EarthyV1';
import EarthyV2 from './EarthyV2';
import EarthyV3 from './EarthyV3';
import PlayfulV1 from './PlayfulV1';
import PlayfulV2 from './PlayfulV2';
import PlayfulV3 from './PlayfulV3';
import PetSuites from './PetSuites';
import OutdoorV1 from './OutdoorV1';
import OutdoorV2 from './OutdoorV2';
import OutdoorV3 from './OutdoorV3';
import PreciseV1 from './PreciseV1';
import PreciseV2 from './PreciseV2';
import Corporate from './Corporate';
import Equine from './Equine';
var themes = {
  ModernV1: ModernV1,
  ModernV2: ModernV2,
  ModernV3: ModernV3,
  EarthyV1: EarthyV1,
  EarthyV2: EarthyV2,
  EarthyV3: EarthyV3,
  PlayfulV1: PlayfulV1,
  PlayfulV2: PlayfulV2,
  PlayfulV3: PlayfulV3,
  PetSuites: PetSuites,
  PreciseV1: PreciseV1,
  PreciseV2: PreciseV2,
  OutdoorV1: OutdoorV1,
  OutdoorV2: OutdoorV2,
  OutdoorV3: OutdoorV3,
  Corporate: Corporate,
  Equine: Equine
};
export default themes;