import { colors } from '../../colors';
var reviewCard = {
  borderRadius: 0,
  reviewTextUppercase: true,
  reviewLinkUppercase: true,
  reviewerNameUppercase: false,
  reviewsHeadlineUppercase: true,
  reviewLinkColor: colors.dirtBrown,
  iconColor: colors.dirtBrown,
  reviewLinkHoverColor: colors.auburn,
  reviewTextColor: colors.metal,
  cardBgColor: colors.powder,
  bgColor: colors.marine,
  headlineColor: colors.white,
  boxShadow: 'none',
  textColorDark: colors.metal,
  textColorLight: colors.white
};
export default reviewCard;