import { colors } from '../../colors';
var threeTile = {
  borderColor: colors.overcast,
  headingColor: colors.slateGrey,
  paraColor: colors.slateGrey,
  subHeadingColor: colors.slateGrey,
  bgColor: colors.white,
  ctaColor: colors.neonPurple,
  cardRadius: '20px',
  textColorDark: colors.slateGrey,
  textColorLight: colors.white,
  cardBgColorVariationC: colors.white
};
export default threeTile;