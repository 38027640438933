import { colors } from '../../colors';
import Next from '../../../../static/images/icons/Next';
import Prev from '../../../../static/images/icons/Prev';
var carousel = {
  arrowBgColor: colors.nvaTeal,
  arrowHoverBgColor: colors.nvaDarkTeal,
  arrowForeColor: colors.white,
  arrowPrevMarginRightVarA: '4px',
  arrowNextMarginLeftVarA: '4px',
  highlightColor: colors.white,
  paginationInactiveColor: colors.nvaLightTeal,
  paginationActiveColor: colors.nvaTeal,
  paginationBorderColor: colors.nvaGreyTeal,
  heroPaginationBorder: false,
  noBorderOnDesktopVariationAandB: true,
  heroPaginationInactiveColor: colors.white,
  heroPaginationInactiveColorMobile: colors.nvaLightTeal,
  heroPaginationInactiveColorVariationC: colors.nvaLightTeal,
  heroPaginationActiveColor: colors.nvaLightTeal,
  heroPaginationBorderColor: colors.nvaTeal,
  nvaHeroPaginationInactiveColor: colors.white,
  nvaHeroPaginationActiveColor: colors.white,
  nvaHeroPaginationBorderColor: colors.white,
  bgColor: colors.white,
  iconNext: Next,
  iconPrev: Prev
};
export default carousel;