import { colors } from '../../colors';
var footer = {
  textColor: colors.black,
  primaryColor: colors.pinkishRed,
  bgColor: colors.paleGrey,
  textHoverColor: colors.white,
  textAddressColor: colors.black,
  textOperationHoursColor: colors.black,
  textPrimaryLinksColor: colors.pinkishRed,
  textPrimaryLinksHoverColor: colors.rouge,
  seperatorColor: colors.overcast,
  tertiaryLinkLetterSpacing: '0.28px',
  textColorDark: colors.black,
  textColorLight: colors.white
};
export default footer;