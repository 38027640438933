import { colors } from '../../colors';
var button = {
  //Hero button
  linkColorHero: colors.white,
  linkColorHeroHover: colors.white,
  backgroundColorHero: colors.nvaTeal,
  backgroundColorHoverHero: colors.nvaDarkTeal,
  borderRadiusHero: '0',
  linkLineHeightHero: '30px',
  heroButtonPadding: '20px 35px',
  //primary button
  linkColorPrimary: colors.white,
  linkColorPrimaryHover: colors.white,
  backgroundColorPrimary: colors.nvaTeal,
  backgroundColorHoverPrimary: colors.nvaDarkTeal,
  borderRadiusPrimary: '0',
  //secondary button
  linkColorSecondary: colors.white,
  linkColorSecondaryHover: colors.nvaTeal,
  backgroundColorSecondary: colors.nvaTeal,
  backgroundColorHoverSecondary: colors.white,
  borderRadiusSecondary: '0',
  borderColorSecondaryHover: 'transparent',
  borderColorSecondary: colors.white,
  // props below alter the styling of the primary button in the footer
  // for this theme, the bg color of the primary is the same as
  // the bg of the footer. This only happens if the bg for the footer
  // is NOT replaced by the bg override.
  // this is a temporary solution.
  hasAlternatePrimaryAnchorFooter: true,
  backgroundColorLightPrimary: colors.nvaDarkTeal,
  backgroundColorHoverLightPrimary: 'rgba(0, 0, 0, 0.1)'
};
export default button;