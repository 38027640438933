import { colors } from '../../colors';
var gallery = {
  bgColor: colors.lightPink,
  textBg: colors.white,
  textColor: colors.copper,
  modalBgColor: colors.white,
  closeIconColor: colors.lead,
  modalHeadingColor: colors.lead,
  modalCaptionColor: colors.copper,
  modalParaColor: colors.lead,
  textColorDark: colors.lead,
  textColorLight: colors.white
};
export default gallery;