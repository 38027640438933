import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function Instagram(props) {
  return __jsx("svg", _extends({
    viewBox: "0 0 14.1 14.1"
  }, props), __jsx("path", {
    d: "M12.4 0H1.8C.8 0 0 .8 0 1.7v10.7c0 1 .8 1.8 1.7 1.8h10.7c1 0 1.8-.8 1.8-1.7V1.8c0-1-.8-1.8-1.8-1.8zM7.1 4.3c1.5 0 2.7 1.3 2.7 2.8 0 1.5-1.3 2.7-2.8 2.7C5.4 9.7 4.2 8.5 4.2 7c.1-1.5 1.3-2.7 2.9-2.7zm5.3 7.6c0 .3-.1.4-.4.4H2.2c-.3 0-.4-.1-.4-.4V5.7l1.1.3c-.2.4-.2.8-.2 1.2.1 2.4 2.1 4.3 4.5 4.3 2.3-.1 4.2-1.9 4.3-4.3 0-.4-.1-.8-.2-1.2l1.1-.2v6.1zm0-7.9c0 .2-.2.4-.4.4h-1.8c-.3 0-.5-.2-.5-.4V2.2c0-.2.2-.4.4-.4h1.8c.2 0 .4.2.4.4l.1 1.8z"
  }));
}
export default Instagram;