import { colors } from '../../colors';
var staff = {
  bgPrimaryColor: colors.white,
  bgSecondaryColor: colors.white,
  bgColorBio: colors.white,
  breadcrumbColor: colors.petsuitesPurple,
  fillColor: colors.petsuitesPurple,
  fillHoverColor: colors.white,
  iconColor: colors.white,
  iconHoverColor: colors.petsuitesPurple,
  iconBorderColor: colors.petsuitesPurple,
  iconBorderHoverColor: colors.petsuitesPurple,
  bladeSubHeadingColor: colors.graphite,
  fiveBladeLinkColor: colors.petsuitesPurple,
  textColorDark: colors.graphite,
  textColorLight: colors.white
};
export default staff;