import { colors } from '../../colors';
var footer = {
  textColor: colors.white,
  primaryColor: colors.white,
  bgColor: colors.darkSage,
  textHoverColor: colors.white,
  textAddressColor: colors.white,
  textOperationHoursColor: colors.white,
  textPrimaryLinksColor: colors.white,
  textPrimaryLinksHoverColor: colors.salmon,
  seperatorColor: colors.overcast,
  tertiaryLinkFontWeight: 'normal',
  copyrightFontWeight: 'normal',
  textColorDark: colors.smoke,
  textColorLight: colors.white
};
export default footer;