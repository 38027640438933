import { colors } from '../../colors';
var staff = {
  bgPrimaryColor: colors.eggShell,
  bgSecondaryColor: colors.salmon,
  bgColorBio: colors.lightTan,
  breadcrumbColor: colors.smoke,
  fillColor: colors.white,
  fillHoverColor: colors.white,
  iconColor: colors.salmon,
  iconHoverColor: colors.wine,
  iconBorderColor: 'transparent',
  iconBorderHoverColor: 'transparent',
  bladeSubHeadingColor: colors.smoke,
  fiveBladeLinkColor: colors.salmon,
  textColorDark: colors.smoke,
  textColorLight: colors.white
};
export default staff;