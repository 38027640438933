import { colors } from '../../colors';
var blogList = {
  borderColor: colors.overcast,
  bgColor: colors.eggShell,
  breadcrumbColor: colors.smoke,
  subHeadingColor: colors.smoke,
  headingColor: colors.smoke,
  paraColor: colors.smoke,
  filterTextColor: colors.smoke
};
export default blogList;