import _defineProperty from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
/**
 * @file Manages the theme variables for Modern V1.
 * @author kkumari
 */
import fonts from './fonts';
import colorsJSON from './colors.json';
//import textures from './textures';

import button from './components/atoms/button';
import anchor from './components/atoms/anchor';
import address from './components/atoms/address';
import input from './components/atoms/input';
import para from './components/atoms/para';
import select from './components/atoms/select';
import socialIcon from './components/atoms/socialIcon';
import fieldError from './components/atoms/fieldError';
import richText from './components/atoms/richText';
import accordion from './components/molecules/accordion';
import blogList from './components/molecules/blogList';
import carousel from './components/molecules/carousel';
import fiftyFiftyCard from './components/molecules/fiftyFiftyCard';
import footer from './components/molecules/footer';
import form from './components/molecules/form';
import header from './components/molecules/header';
import book from './components/molecules/book';
import imageGallery from './components/molecules/imageGallery';
import jumplink from './components/molecules/jumplink';
import pagination from './components/molecules/pagination';
import reviewCard from './components/molecules/reviewCard';
import socialFeed from './components/molecules/socialFeed';
import card from './components/molecules/card';
import contactUsNva from './components/molecules/contactUsNva';
import alert from './components/organisms/alert';
import fiveCard from './components/organisms/fiveCard';
import gallery from './components/organisms/gallery';
import imageSlider from './components/organisms/imageSlider';
import location from './components/organisms/location';
import pencilBanner from './components/organisms/pencilBanner';
import service from './components/organisms/service';
import staff from './components/organisms/staff';
import threeTile from './components/organisms/threeTile';
import promoBanner from './components/organisms/promoBanner';
import { colors } from './colors';
var theme = {
  //font color properties
  fontColorP: colors.lead,
  fontColorH1: colors.lead,
  fontColorH2: colors.lead,
  fontColorH3: colors.lead,
  fontColorH4: colors.lead,
  fontColorBaseH5: colors.copper,
  fontColorH5: colors.lead,
  //font properties
  fontFamilyBtn: fonts.FONT_LATO_REGULAR,
  fontFamilyIcons: fonts.FONT_ICONS,
  fontSizeBase: fonts.FONT_SIZE_BASE,
  fontSizeSmall: fonts.FONT_SIZE_SMALL,
  fontSizeDefault: fonts.FONT_SIZE_DEFAULT,
  lineHeightDefault: fonts.FONT_LINE_HEIGHT_DEFAULT,
  letterSpacingDefault: fonts.FONT_LETTER_SPACING_DEFAULT,
  fontFamilyH1: fonts.FONT_ABRILFATFACE_REGULAR,
  fontSizeH1: fonts.FONT_SIZE_H1,
  lineHeightH1: fonts.LINE_HEIGHT_H1,
  letterSpacingH1: fonts.LETTER_SPACING_H1,
  fontWeightH1: fonts.FONT_WEIGHT_NORMAL,
  fontFamilyH2: fonts.FONT_ABRILFATFACE_REGULAR,
  fontSizeH2: fonts.FONT_SIZE_H2,
  lineHeightH2: fonts.LINE_HEIGHT_H2,
  letterSpacingH2: fonts.LETTER_SPACING_H2,
  fontWeightH2: fonts.FONT_WEIGHT_NORMAL,
  fontFamilyH3: fonts.FONT_ABRILFATFACE_REGULAR,
  fontSizeH3: fonts.FONT_SIZE_H3,
  lineHeightH3: fonts.LINE_HEIGHT_H3,
  letterSpacingH3: fonts.LETTER_SPACING_H3,
  fontWeightH3: fonts.FONT_WEIGHT_NORMAL,
  fontFamilyH4: fonts.FONT_LATO_BOLD,
  fontSizeH4: fonts.FONT_SIZE_H4,
  lineHeightH4: fonts.LINE_HEIGHT_H4,
  letterSpacingH4: fonts.LETTER_SPACING_H4,
  fontWeightH4: fonts.FONT_WEIGHT_NORMAL,
  fontFamilyH5: fonts.FONT_LATO_REGULAR,
  fontSizeH5: fonts.FONT_SIZE_H5,
  lineHeightH5: fonts.LINE_HEIGHT_H5,
  letterSpacingH5: fonts.LETTER_SPACING_H5,
  fontWeightH5: fonts.FONT_WEIGHT_NORMAL,
  fontFamilyP: fonts.FONT_LATO_REGULAR,
  fontSizeP: fonts.FONT_SIZE_P,
  lineHeightP: fonts.LINE_HEIGHT_P,
  letterSpacingP: fonts.LETTER_SPACING_P,
  //Desktop properties
  desktop: {
    fontSizeH1: fonts.DESKTOP.FONT_SIZE_H1,
    lineHeightH1: fonts.DESKTOP.LINE_HEIGHT_H1,
    letterSpacingH1: fonts.DESKTOP.LETTER_SPACING_H1,
    fontSizeH2: fonts.DESKTOP.FONT_SIZE_H2,
    lineHeightH2: fonts.DESKTOP.LINE_HEIGHT_H2,
    letterSpacingH2: fonts.DESKTOP.LETTER_SPACING_H2,
    fontSizeH3: fonts.DESKTOP.FONT_SIZE_H3,
    lineHeightH3: fonts.DESKTOP.LINE_HEIGHT_H3,
    letterSpacingH3: fonts.DESKTOP.LETTER_SPACING_H3,
    fontSizeH4: fonts.DESKTOP.FONT_SIZE_H4,
    lineHeightH4: fonts.DESKTOP.LINE_HEIGHT_H4,
    letterSpacingH4: fonts.DESKTOP.LETTER_SPACING_H4,
    fontSizeH5: fonts.DESKTOP.FONT_SIZE_H5,
    lineHeightH5: fonts.DESKTOP.LINE_HEIGHT_H5,
    letterSpacingH5: fonts.DESKTOP.LETTER_SPACING_H5,
    fontSizeP: fonts.DESKTOP.FONT_SIZE_P,
    lineHeightP: fonts.DESKTOP.LINE_HEIGHT_P,
    letterSpacingP: fonts.DESKTOP.LETTER_SPACING_P
  },
  fonts: fonts.FONT_FACES,
  //All color palettes
  palette: colorsJSON
};
var themes = _objectSpread(_objectSpread({}, theme), {}, {
  button: button,
  anchor: anchor,
  address: address,
  input: input,
  para: para,
  select: select,
  socialIcon: socialIcon,
  fieldError: fieldError,
  accordion: accordion,
  blogList: blogList,
  carousel: carousel,
  fiftyFiftyCard: fiftyFiftyCard,
  footer: footer,
  form: form,
  header: header,
  book: book,
  imageGallery: imageGallery,
  jumplink: jumplink,
  pagination: pagination,
  reviewCard: reviewCard,
  socialFeed: socialFeed,
  card: card,
  alert: alert,
  fiveCard: fiveCard,
  gallery: gallery,
  imageSlider: imageSlider,
  location: location,
  pencilBanner: pencilBanner,
  service: service,
  threeTile: threeTile,
  staff: staff,
  promoBanner: promoBanner,
  contactUsNva: contactUsNva,
  richText: richText
});
export default themes;