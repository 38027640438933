import { colors } from '../../colors';
var fiveCard = {
  textColor: colors.smoke,
  hoverColor: colors.salmon,
  bgColorPara: colors.lightTan,
  bgColor: colors.lightTan,
  cardBgColorB: colors.darkSage,
  cardBgColorHoverB: colors.salmon,
  cardCTAColorB: colors.white,
  cardCTAColorHoverB: colors.darkSage,
  iconColor: colors.white,
  iconColorHover: colors.darkSage
};
export default fiveCard;