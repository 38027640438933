import { colors } from '../../colors';
import { transparentize } from 'polished';
var reviewCard = {
  borderRadius: 0,
  reviewLinkColor: colors.cornflowerBlue,
  iconColor: colors.cornflowerBlue,
  reviewLinkHoverColor: colors.frenchBlue,
  reviewTextColor: colors.lead,
  cardBgColor: colors.white,
  bgColor: colors.white,
  headlineColor: colors.lead,
  boxShadow: "0 0 10px 0 ".concat(transparentize(0.9, '#000')),
  textColorDark: colors.lead,
  textColorLight: colors.white
};
export default reviewCard;