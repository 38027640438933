import { colors } from '../../colors';
var reviewCard = {
  borderRadius: 0,
  reviewLinkColor: colors.brickOrange,
  reviewLinkHoverColor: colors.wine,
  reviewTextColor: colors.smoke,
  iconColor: colors.salmon,
  cardBgColor: colors.white,
  bgColor: colors.eggShell,
  headlineColor: colors.smoke,
  boxShadow: 'none',
  textColorDark: colors.smoke,
  textColorLight: colors.white
};
export default reviewCard;