import { colors } from '../../colors';
var service = {
  bgColor: colors.offWhite,
  borderColor: colors.charcoalGrey,
  bgColorBlade: colors.offWhite,
  carouselDotColor: colors.charcoalGrey,
  linkColor: colors.dustyTeal,
  textColorDark: colors.charcoalGrey,
  textColorLight: colors.white
};
export default service;