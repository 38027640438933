import { colors } from '../../colors';
var blogList = {
  borderColor: colors.overcast,
  bgColor: colors.whiteish,
  breadcrumbColor: colors.onyx,
  subHeadingColor: colors.onyx,
  headingColor: colors.onyx,
  paraColor: colors.onyx,
  filterTextColor: colors.onyx
};
export default blogList;